const _ = require('underscore');
const validator = require('validate.js');

const stringHelpers = require('../../src/utils/strings');

const { leftTruncateForAnyLength } = stringHelpers;

class Model {
    constructor(modelData = {}, beforeData) {
        const nodeWasDeleted = (
            _.isObject(beforeData) && !_.isEmpty(beforeData) // used to be something
            && _.isEmpty(modelData) // nothing now
        );
        const newModelData = {
            beforeData:    {},
            dbNodeDeleted: nodeWasDeleted,
            ...modelData,
        };

        const hasBeforeData = _.isObject(beforeData) && !_.isEmpty(beforeData);
        if (hasBeforeData) {
            newModelData.beforeData = beforeData; // Stash it if we have it.
            // Override functions will use this to create models as necessary.
        }

        this.fillWithDefaultsAndSetProperties(newModelData);
    }

    // Currently this is here for readability/simplicity, but it could become a more complicated check too.
    get shouldUpdateProjectsCanUse() {
        return this.displayDataHasChanged;
    }

    get wasJustDeleted() {
        return this.isDeleted && !this.wasAlreadyDeleted;
    }

    get isNew() {
        return _.isObject(this.beforeData) && _.isEmpty(this.beforeData);
    }

    get isDeleted() {
        return !_.isUndefined(this.timeDeleted) || this.dbNodeDeleted;
    }

    get wasAlreadyDeleted() {
        return this.hasAllowedBeforeData && !_.isUndefined(this.beforeData.timeDeleted);
    }

    get hasAllowedBeforeData() {
        return _.isObject(this.beforeData) && !_.isEmpty(this.beforeData);
    }

    get allowedProjectsFromCurrentOrPrevious() {
        if (this.wasJustDeleted) {
            return this.previousModel.allowedProjects;
        }

        return this.allowedProjects;
    }

    static get nameValidationRules() {
        return {
            name: {
                type:   'string',
                length: {
                    minimum:  1,
                    tooShort: 'must be at least %{count} characters!',
                },
                presence: {
                    message: 'is required',
                },
            },
        };
    }

    static get nameDefault() {
        return {
            name: '',
        };
    }

    static generateNewBasicError(label, errorMessage, errorData) {
        return {
            label,
            errorMessage,
            errorData,
        };
    }

    static handleValidation(propsToValidate, constraints) {
        const handledValidationResultJSON = { isValid: true };
        Object.keys(propsToValidate).forEach((key) => {
            handledValidationResultJSON[key] = {
                isValid:  true,
                errors:   [''],
                modelVal: propsToValidate[key],
            };
        });

        const validationResults = validator(propsToValidate, constraints);
        if (!validationResults) {
            return handledValidationResultJSON;
        }

        handledValidationResultJSON.isValid = false;

        Object.keys(validationResults).forEach((failedKey) => {
            handledValidationResultJSON[failedKey] = {
                isValid: false,
                errors:  validationResults[failedKey],
            };
        });

        return handledValidationResultJSON;
    }

    get displayDataHasChanged() {
        if (this.isNew) {
            return true;
        }
        if (this.wasJustDeleted) {
            return true;
        }
        if (this.isDeleted) {
            return false; // unless it's just been deleted, we don't care.
        }
        return this.oldAndNewDisplayDataDontMatch;
    }

    get oldAndNewDisplayDataDontMatch() {
        return !_.isEqual(this.getOldDataForDisplay(), this.getDataForDisplay(this));
    }

    leftTruncateValOrBlank(key) {
        const currKey = this[key];

        if (_.isUndefined(currKey)) {
            return '';
        }
        return leftTruncateForAnyLength(currKey);
    }

    getOldDataForDisplay() {
        // If this model is new, we know its previous model will be nothing.
        if (this.isNew) {
            return {};
        }

        return this.previousModel.getDataForDisplay();
    }

    fillWithDefaultsAndSetProperties(modelDataObject = {}) {
        if (_.isFunction(this.preProcessDefaultData)) {
            this.preProcessDefaultData(modelDataObject);
        }

        this.setPropertiesForGivenJSONBlob(modelDataObject);

        if (_.isFunction(this.postProcessDefaultData)) {
            this.postProcessDefaultData(modelDataObject);
        }
    }

    setPropertiesForGivenJSONBlob(data = {}) {
        if (!_.isObject(data)) {
            return;
        }

        Object.keys(data).forEach((key) => {
            this.setDeviceProperty(key, data[key]);
        });
    }

    setDeviceProperty(key, value) {
        this[key] = value;
    }
}

module.exports = Model;
