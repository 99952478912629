const generateFieldsForBTable = (key, label, sortable = true) => ({
    key,
    label,
    sortable,
});

const getNewTimestamp = () => (new Date()).getTime();

module.exports = {
    generateFieldsForBTable,
    getNewTimestamp,
};
