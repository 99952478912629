const _ = require('underscore');
const Model = require('./Model');

class Connection extends Model {
    static get validationRules() {
        return {
            ...Model.nameValidationRules,
            maxLoopCount: {
                numericality: {
                    onlyInteger:             true,
                    greaterThanOrEqualTo:    0,
                    lessThanOrEqualTo:       10,
                    notGreaterThanOrEqualTo: 'must be less than 10',
                    notLessThanOrEqualTo:    'must be greater than or equal to 0',
                },
            },
        };
    }

    static get refPath() {
        return '/connections/';
    }

    static get defaultVals() {
        return {
            ...Model.nameDefault,
            maxLoopCount: 0,
        };
    }

    /**
     * If it's been deleted, we don't want it to trickle out.
     */
    static detailsShouldNotTrickleOut(connectionData) {
        if (!connectionData || !_.isObject(connectionData) || connectionData.timeDeleted) {
            return true;
        }

        return false;
    }

    /**
     * If it's been deleted or a null or undefined or (not-object) is added, we don't want it to trickle out.
     */
    static getDataForDisplay(connectionDetails) {
        if ((new Connection(connectionDetails).isDeleted)) {
            return null;
        }

        return {
            connectionKey:           connectionDetails.connectionKey,
            name:                    connectionDetails.name,
            createdAtLocal:          connectionDetails.createdAtLocal,
            sequenceKey:             connectionDetails.sequenceKey,
            triggersSequenceItemKey: connectionDetails.triggersSequenceItemKey,
            createdInProjectID:      connectionDetails.createdInProjectID,
            updatedAt:               connectionDetails.updatedAt,
        };
    }

    /**
     * Create a connection JSON to be saved in the DB
     */
    static generateJSONBlobForDB(modelConnectionDetails) {
        const tempConnectionJSONForDB = {
            connectionName: modelConnectionDetails.name,
            maxLoopCount:   modelConnectionDetails.maxLoopCount,
        };

        return tempConnectionJSONForDB;
    }

    fillWithDefaultConnectionData() {
        this.setPropertiesForGivenJSONBlob(Connection.defaultVals);
    }

    preProcessDefaultData() {
        this.fillWithDefaultConnectionData();
    }

    /**
     * Valides this connection.
     */
    validate() {
        return Connection.handleValidation({
            name:         this.name,
            maxLoopCount: this.maxLoopCount,
        }, Connection.validationRules);
    }
}

module.exports = Connection;
