export default {
    setLocalAccount(state, account) {
        state.account = account;
    },

    setLocalUserProjects(state, projects) {
        state.projects = projects;
    },

    clearLocalAccount(state) {
        state.account = null;
    },
};
