const _ = require('underscore');
const FrontendDevice = require('./FrontendDevice');
const ReceiverStage = require('../TriggerStage');

class FrontendReceiverStage extends ReceiverStage {
    get availableDevicesForSelectionDropdown() {
        return _.toArray(this.frontendDevicesByDeviceKey);
    }

    get selectedFrontendDeviceArray() {
        // https://underscorejs.org/#where
        return _.where(this.frontendDevicesByDeviceKey, { isSelected: true });
    }

    static get defaultVals() {
        return {
            frontendDevicesByDeviceKey: {},
            stageNumber:                0,
        };
    }

    fillWithFrontendTriggerStageDefaults() {
        this.setPropertiesForGivenJSONBlob(FrontendReceiverStage.defaultVals);
    }

    fillWithDefaultData(defaultsData) {
        this.fillWithTriggerStageDefaults(defaultsData);
        this.fillWithFrontendTriggerStageDefaults();
    }

    getFrontendDeviceOrNull(deviceKey) {
        const tempDeviceForKeyValidation = FrontendDevice.isValidDeviceKey(deviceKey);
        if (!tempDeviceForKeyValidation) {
            return null;
        }

        let frontendDeviceIfExists = null;
        _.mapObject(this.frontendDevicesByDeviceKey, (currFrontendDevice) => {
            // isNull check just to make it that much faster.
            if (_.isNull(frontendDeviceIfExists) && currFrontendDevice.deviceKey === deviceKey) {
                frontendDeviceIfExists = currFrontendDevice;
            }
        });
        return frontendDeviceIfExists;
    }

    removeSelectedDeviceAndUpdateAvailableDevices(selectedTriggerDevice) {
        const deviceKeyToBeRemoved = selectedTriggerDevice.deviceKey;
        const deviceToBeRemovedIndex = this.selectedFrontendDeviceArray
            .findIndex((element) => element.deviceKey === deviceKeyToBeRemoved); // Find index of element of same key
        this.selectedFrontendDeviceArray.splice(deviceToBeRemovedIndex, 1); // Removes the device at that index
        const frontendDeviceInDropdown = this.availableDevicesForSelectionDropdown
            .find((element) => element.deviceKey === deviceKeyToBeRemoved); // Finds device in trigger device dropdown
        frontendDeviceInDropdown.isSelected = false; // Enables it for selection again
    }
}

module.exports = FrontendReceiverStage;
