<template>
    <div>
        <h1>604 Labs</h1>
        <b-button
            variant="success"
            @click="login"
        >
            Signin with Google
        </b-button>
    </div>
</template>

<script>
    export default {
        methods: {
            login() {
                this.$store.dispatch('user/login').then(() => {
                    this.$router.push({ name: 'home' });
                });
            },
        },
    };
</script>
