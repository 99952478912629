<template>
    <b-form>
        <b-container>
            <b-container
                v-for="(item, index) in recieverStageArray"
                :key="item"
                class="border my-2 p-4"
            >
                <b-row>
                    <receiver-stage
                        :reciever-model="item"
                    />
                </b-row>
                <b-row align-h="end">
                    <b-button
                        variant="danger"
                        @click="removeReceiverStage(index)"
                    >
                        Remove Receiver Stage
                    </b-button>
                </b-row>
            </b-container>
            <b-row>
                <b-button @click="addReceiverStage">
                    Add Receiver Stage
                </b-button>
            </b-row>
        </b-container>
    </b-form>
</template>

<script>
    import FrontendReceiverStage from '../../../../../shared/models/frontend/FrontendReceiverStage';
    import ReceiverStage from './ReceiverStage.vue';

    export default {
        components: {
            ReceiverStage,
        },

        data() {
            return {
                formValues: {
                    // Used for setting default dropdown value on Trigger stage devices
                    currentlySelectedDeviceDropdownValue: 'default',
                },
                recieverStageArray: [],
            };
        },

        methods: {
            addReceiverStage() {
                const newReceiverStage = new FrontendReceiverStage({});
                this.recieverStageArray.push(newReceiverStage);
            },

            removeReceiverStage(index) {
                this.recieverStageArray.splice(index, 1); // Removes receiver stage at given index
            },
        },

    };
</script>
