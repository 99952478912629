<template>
    <div>
        <b-table
            responsive
            :fields="fields"
            :items="getStageDevices()"
        />
    </div>
</template>

<script>

    export default {
        props: {
            frontendConnectionMonitor: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        data() {
            return {
                fields: [
                    {
                        key:      'formattedDeviceKey',
                        label:    'Device Key',
                        sortable: true,
                    },
                    {
                        key:      'formattedReroutedToConnectionMonitorKey',
                        label:    'Rerouted to CM Key',
                        sortable: true,
                    },
                    {
                        key:      'reroutedToStage',
                        label:    'Stage #',
                        sortable: true,
                    },
                ],
            };
        },
        methods: {
            getStageDevices() {
                return this.frontendConnectionMonitor.frontendDevicesForCurrStage;
            },
        },
    };
</script>
