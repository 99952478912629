<template>
    <div>
        <b-row>
            <b-col cols="9">
                <p class="title">
                    {{ title }}
                </p>
            </b-col>
            <b-col cols="3">
                <b-button
                    class="create-project-button"
                    variant="primary"
                    @click="openCreateProjectModal"
                >
                    + Create New Project
                </b-button>
            </b-col>
        </b-row>

        <div class="projects">
            <project-card
                v-for="project in userProjects"
                :key="project.key"
                class="project-card"
                :project="project"
            />
        </div>

        <create-project-modal ref="create-project-modal" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import CreateProjectModal from './CreateProjectModal.vue';
    import ProjectCard from './ProjectCard.vue';

    export default {
        components: {
            ProjectCard,
            CreateProjectModal,
        },

        props: {
            title: {
                type:    String,
                default: 'Projects',
            },
        },

        computed: {
            ...mapGetters('user', {
                user:              'getUser',
                currentProjectKey: 'getCurrentProjectKey',
            }),
            userProjects() {
                if (!this.user?.projects) {
                    return [];
                }

                const projects = [];
                Object.keys(this.user.projects).forEach((projectKey) => {
                    projects.push({ key: projectKey, name: this.user.projects[projectKey] });
                });

                return projects;
            },
        },

        methods: {
            openCreateProjectModal() {
                this.$refs['create-project-modal'].show();
            },
        },
    };
</script>

<style scoped>
    .title {
        font-size: 1.5em;
    }

    .project-card {
        margin-bottom: 15px;
    }

    .create-project-button {
        margin-left: 73px;
    }
</style>
