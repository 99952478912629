const _ = require('underscore');
const FrontendDevice = require('./FrontendDevice');
const TriggerStage = require('../TriggerStage');

class FrontendTriggerStage extends TriggerStage {
    static get defaultVals() {
        return {
            frontendDevicesByDeviceKey: {},
        };
    }

    fillWithFrontendTriggerStageDefaults() {
        this.setPropertiesForGivenJSONBlob(FrontendTriggerStage.defaultVals);
    }

    preProcessDefaultData(defaultsData) {
        this.fillWithTriggerStageDefaults(defaultsData);
        this.fillWithFrontendTriggerStageDefaults();
    }

    getFrontendDeviceOrNull(deviceKey) {
        const tempDeviceForKeyValidation = FrontendDevice.isValidDeviceKey(deviceKey);
        if (!tempDeviceForKeyValidation) {
            return null;
        }

        let frontendDeviceIfExists = null;
        _.mapObject(this.frontendDevicesByDeviceKey, (currFrontendDevice) => {
            // isNull check just to make it that much faster.
            if (_.isNull(frontendDeviceIfExists) && currFrontendDevice.deviceKey === deviceKey) {
                frontendDeviceIfExists = currFrontendDevice;
            }
        });
        return frontendDeviceIfExists;
    }
}

module.exports = FrontendTriggerStage;
