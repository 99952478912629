const _ = require('underscore');
const stringHelpers = require('../../src/utils/strings');

const { formatTimestamp } = stringHelpers;

const Model = require('./Model');

class ConnectionMonitor extends Model {
    get currentStageNumber() {
        return Object.keys(this.cleanedFinishedStagesObject).length;
    }

    get devicesWereRerouted() {
        return this.finishedSuccessfully === false;
    }

    get currLoopCount() {
        return this.startedLoopCount;
    }

    get hasMoreLoopsToGo() {
        return (this.maxLoopCount > this.currLoopCount);
    }

    get isCurrentlyActive() {
        return !_.isBoolean(this.finishedSuccessfully);
    }

    get isAtEndOfLoop() {
        return this.currentStageNumber === this.numStagesInCMSequence;
    }

    get cleanedFinishedStagesObject() {
        if (_.isObject(this.finishedStages)) {
            return this.finishedStages;
        }
        return {};
    }

    get currentStageKey() {
        return this.sequenceItemKeysByOrder[this.currentStageNumber];
    }

    get numStagesInCMSequence() {
        return Object.keys(this.sequenceItems).length;
    }

    get currentStageData() {
        const currStageKey = this.currentStageKey;
        const currStages = this.sequenceItems;

        if (!_.isObject(currStages) || _.isEmpty(currStages) || !_.isString(currStageKey)) {
            return null;
        }

        return currStages[currStageKey];
    }

    getStageKeyByOrder(index) {
        return this.sequenceItemKeysByOrder[index];
    }

    getStageDataByOrder(index) {
        const key = this.getStageKeyByOrder(index);
        return this.getStageDataByKey(key);
    }

    getStageDataByKey(key, usePreviousModel = false) {
        if (usePreviousModel) {
            return this.previousModel.sequenceItems[key];
        }
        return this.sequenceItems[key];
    }

    formatTimestampFromPropertyKey(keyToFormat) {
        return formatTimestamp(this[keyToFormat], 'h:mm:ss a - M/D/YYYY');
    }
}

module.exports = ConnectionMonitor;
