<template>
    <div>
        <h1>{{ account.displayName }}'s Settings</h1>
        <b-button
            variant="primary"
            @click="goBackToHome"
        >
            Back to Home
        </b-button>
        <b-row class="account-information-row">
            <b-col>
                <b-card header="Your account information">
                    <img
                        :src="account.photoURL"
                        alt="Picture"
                    >
                    <p>UID: {{ account.uid }}</p>
                    <p>Name: {{ account.displayName }}</p>
                    <p>Email: {{ account.email }}</p>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12">
                <projects-view
                    class="projects-view"
                    :title="'Your Projects'"
                />
            </b-col>
        </b-row>
        <b-row class="logout-button-row">
            <b-col>
                <b-button
                    variant="danger"
                    @click="handleLogout"
                >
                    Logout
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    import ProjectsView from '@c/user/projects/ProjectsView';

    export default {
        components: {
            ProjectsView,
        },

        computed: {
            ...mapGetters('user', {
                account: 'getAccount',
            }),
        },

        methods: {
            ...mapActions('user', [
                'logout',
            ]),
            handleLogout() {
                this.logout().then(() => {
                    this.$router.push({ name: 'login' });
                });
            },

            goBackToHome() {
                this.$router.push({ name: 'home' });
            },
        },
    };
</script>

<style scoped>
    .projects-view {
        margin-bottom: 10px;
    }

    .account-information-row {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .logout-button-row {
        margin-bottom: 40px;
    }
</style>
