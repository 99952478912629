<template>
    <b-form>
        <b-container>
            <b-row>
                <b-form-group
                    id="input-group-receiver-stage-name"
                    label="Receiver Stage Name"
                    label-for="receiver-stage-name-input"
                >
                    <b-form-input
                        id="receiver-stage-name-input"
                        v-model="currReceiverStage.name"
                        placeholder="Enter name"
                        type="text"
                    />
                </b-form-group>
            </b-row>
            <b-row>
                <b-form-group
                    id="input-group-receiver-stage-full-finish"
                    label="Require whole stage to finish? (As opposed to just one device)"
                    label-for="receiver-stage-full-stage-finish"
                >
                    <b-form-select
                        id="receiver-stage-full-stage-finish"
                        v-model="receiverFullStageFinish"
                        :options="yesOrNoDropdownChoices"
                    />
                </b-form-group>
            </b-row>
            <b-row>
                <b-form-group
                    id="input-group-receiver-stage-trickle-from-trigger"
                    label="Use value from the trigger that started the sequence?
                        (If selected, receiver values below will be saved (for validation, methinks), but ultimately ignored."
                    label-for="receiver-stage-trickle-from-trigger"
                >
                    <b-form-select
                        id="receiver-stage-trickle-from-trigger"
                        v-model="receiverTrickleFromTrigger"
                        :options="yesOrNoDropdownChoices"
                    />
                </b-form-group>
            </b-row>
            <b-row>
                <b-form-group
                    id="input-group-receiver-stage-devices"
                    label="Select the Receiver Devices"
                    label-for="receiver-stage-device-input"
                >
                    <!-- receiverDeviceDropdownPlaceholder is a way to auto set the value back to default -->
                    <b-form-select
                        id="receiver-stage-device-input"
                        v-model="receiverDeviceDropdownPlaceholder"
                        :options="receiverDeviceOptions"
                        :disabled="noDevicesInProject"
                        @change="handleSelectedDevice"
                    />
                </b-form-group>
                <b-alert
                    variant="danger"
                    :show="noDevicesInProject"
                >
                    Add devices to your project to create connections!
                </b-alert>
            </b-row>
            <b-row>
                <b-table
                    ref="selectedReceiverDeviceTable"
                    bordered
                    :items="currReceiverStage.selectedFrontendDeviceArray"
                    :fields="selectedReceiverDeviceFields"
                    show-empty
                >
                    <template #empty="">
                        <p> There are no devices currently added </p>
                    </template>
                    <template #cell(conditions)="data">
                        <b-form-group
                            id="input-group-connection-loop"
                            label="Comparison for receiver activation:"
                            label-for="selectedDeviceComparisonType"
                        >
                            <b-form-select
                                id="selectedDeviceComparisonType"
                                v-model="data.item.comparisonType"
                                :options="data.item.optionsForDeviceReceiverComparisonType"
                            />
                        </b-form-group>
                        <div v-if="data.item.comparisonTypeForDropdownAllowsInput">
                            <div v-if="data.item.isPercentDevice">
                                <b-form-group
                                    label="Comparison value:"
                                    label-for="device-comparison-value-input"
                                    append="%"
                                >
                                    <b-form-input
                                        id="device-comparison-value-input"
                                        v-model="data.item.comparisonVal"
                                        type="number"
                                    />
                                </b-form-group>
                            </div>
                            <div v-else-if="data.item.isBooleanDevice">
                                <b-form-group
                                    label="Comparison Value:"
                                    label-for="device-comparison-boolean-value-input"
                                >
                                    <b-form-select
                                        id="device-comparison-boolean-value-input"
                                        v-model="data.item.comparisonVal"
                                        :options="trueOrFalseOptions"
                                    />
                                </b-form-group>
                            </div>
                        </div>
                    </template>
                    <template #cell(remove)="data">
                        <b-button
                            @click="removeSelectedDevice(data.item)"
                        >
                            Remove
                        </b-button>
                    </template>
                </b-table>
            </b-row>
        </b-container>
    </b-form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import FrontendReceiverStage from '../../../../../shared/models/frontend/FrontendReceiverStage';
    import generateFieldsForBTable from '../../../../utils/frontendUtils';

    const _ = require('underscore');

    export default {

        props: {
            receiverModel: {
                type:    FrontendReceiverStage,
                default: new FrontendReceiverStage(),
            },
        },

        data() {
            return {
                formValues: {
                    // Used for setting default dropdown value on Receiver stage devices
                    currentlySelectedDeviceDropdownValue: 'default',
                },
                selectedReceiverDeviceFields: [
                    // https://stackoverflow.com/questions/51541671/make-bootstrap-vue-b-table-id-column-invisible
                    { key: 'deviceKey', thClass: 'd-none', tdClass: 'd-none' },
                    generateFieldsForBTable('name', 'Name'),
                    generateFieldsForBTable('deviceTypeName', 'deviceTypeName'),
                    generateFieldsForBTable('conditions', 'Conditions', false),
                    generateFieldsForBTable('remove', 'Remove', false),
                ],
                receiverDeviceDropdownPlaceholder: 'default',
                yesOrNoDropdownChoices:            [
                    { text: 'No', value: false },
                    { text: 'Yes', value: true },
                ],
                receiverFullStageFinish:    false,
                receiverTrickleFromTrigger: false,
            };
        },

        computed: {
            ...mapGetters('user', {
                currentProjectKey:                'getCurrentProjectKey',
                someCurrentFrontendDevicesObject: 'getCurrentProjectFrontendDevicesObject',
            }),

            validation() {
                return this.validateConnectionFromFormValues();
            },

            modal() {
                return this.$refs.modal;
            },

            noDevicesInProject() {
                if (!this.receiverDeviceOptions) {
                    return false;
                }
                return this.receiverDeviceOptions.length <= 1;
            },
        },

        created() {
            this.createModal();
            this.receiverDeviceOptions = this.generateReceiverDeviceDropdownOptions();
        },

        methods: {
            ...mapActions('user', [
                'getProjectsCanuseConnectionRef',
            ]),
            show() {
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },

            properyIsValid(key) {
                if (_.isNull(this.formValues[key])) {
                    return null;
                }
                return this.validation[key].isValid;
            },

            generateReceiverDeviceDropdownOptions() {
                const { currReceiverStage } = this;
                return [
                    { value: 'default', text: 'Select a device from the dropdown...' },
                    ...currReceiverStage.availableDevicesForSelectionDropdown,
                ];
            },

            // Combination of all validations. Will need to be redone at some point to mirrow
            // how we did validations with Shiv.
            validateConnectionFromFormValues() {
                return this.currReceiverStage.validate();
            },

            handleSelectedDevice(currentlySelectedDeviceName) {
                // Undefined is passed onCreate (onMount?).
                const { currReceiverStage } = this;
                const getFrontendDeviceorNull = currReceiverStage.getFrontendDeviceOrNull(currentlySelectedDeviceName);

                if (!_.isNull(getFrontendDeviceorNull)) {
                    currReceiverStage.markFrontendDeviceAsSelected(getFrontendDeviceorNull.deviceKey);
                }
                // Always reset to default.
                this.receiverDeviceDropdownPlaceholder = 'default';
            },

            removeSelectedDevice(currSelectedDeviceKey) {
                this.currReceiverStage.markFrontendDeviceAsNotSelected(currSelectedDeviceKey);
            },

            formatDeviceName(deviceName, deviceType, isPercent) {
                const percentOrBoolean = isPercent ? 'Percent' : 'Boolean';
                return `${deviceName} (${deviceType} - ${percentOrBoolean})`;
            },

            createModal() {
                this.currReceiverStage = new FrontendReceiverStage({
                    stage: this.stageNumber,
                });
                this.formValues = {
                    name: this.currReceiverStage.name,
                };
            },
        },

    };
</script>
