const admin = require('firebase-admin');

const serviceAccount = require('./keys/testServiceAccountCreds.json');

const TESTING_WITH_PRODUCTION = false;

let config; // undefined because admin.initializeApp(config) doesn't like null.
if (TESTING_WITH_PRODUCTION) {
    config = {
        credential:  admin.credential.cert(serviceAccount),
        databaseURL: 'https://labs-604.firebaseio.com',
    };
}

const app = admin.initializeApp(config);

module.exports = app;
