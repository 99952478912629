<template>
    <b-modal
        ref="modal"
        title="Create New Project"
        centered
        ok-title="Create"
        :ok-disabled="!formIsValid"
        @ok="handleOK"
        @show="resetModal"
        @hidden="resetModal"
    >
        <b-form-group
            label="Project Name"
            label-for="project-name-input"
            invalid-feedback="Project name must be longer than 3 characters."
            :state="formIsValid"
        >
            <b-form-input
                id="project-name-input"
                v-model="projectName"
                :state="formIsValid"
                trim
            />
        </b-form-group>
        <b-form-group
            label-for="make-active-input"
            :state="formIsValid"
        >
            <b-form-checkbox
                id="make-active-input"
                v-model="makeActiveAfterCreation"
                :value="true"
                :unchecked-value="false"
            >
                Make Active Project Once Created
            </b-form-checkbox>
        </b-form-group>
    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex';
    import { getCloudFunctionByName } from '@/firebase';

    export default {
        data() {
            return {
                projectName:             null,
                makeActiveAfterCreation: true,
            };
        },

        computed: {
            formIsValid() {
                if (this.projectName === null) {
                    return null;
                }
                return this.projectName.length > 3;
            },
        },

        methods: {
            ...mapActions('user', {
                switchActiveProject: 'bindLocalProjectToProjectKey',
            }),
            handleOK(evt) {
                evt.preventDefault();
                if (!this.formIsValid) {
                    return;
                }

                getCloudFunctionByName('createProject')({
                    projectName: this.projectName,
                }).then((result) => {
                    if (this.makeActiveAfterCreation) {
                        this.switchActiveProject(result.data);
                    }
                    this.hide();
                });
            },
            resetModal() {
                this.projectName = null;
            },
            show() {
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },
        },
    };
</script>

<style>

</style>
