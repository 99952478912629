const _ = require('underscore');
const stringHelpers = require('../../src/utils/strings');

const { formatTimestamp } = stringHelpers;

const Model = require('./Model');

const EPOGlobalUtils = require('../../functions/604GlobalUtils');

class Device extends Model {
    get deviceValUpdatedAtTimestampLabel() {
        return this.formatTimestampFromPropertyKey('deviceValUpdatedAt');
    }

    get idealValUpdatedAtTimestampLabel() {
        return this.formatTimestampFromPropertyKey('idealValUpdatedAt');
    }

    get isBooleanDevice() {
        return this.deviceUseBoolean === true;
    }

    get isPercentDevice() {
        return this.deviceUsePercent === true;
    }

    get deviceValMatchesIdealVal() {
        return (this.deviceVal == this.idealVal);
    }

    get deviceOrIdealValUndefined() {
        return (_.isUndefined(this.deviceVal) || _.isUndefined(this.idealVal));
    }

    static get deviceValValidationRules() {
        return Device.deviceAndIdealValValidationRules();
    }

    static get idealValValidationRules() {
        return Device.deviceAndIdealValValidationRules(true);
    }

    static deviceAndIdealValValidationRules(useIdealVal = false) {
        let deviceOrIdealVal = '';
        let deviceOrIdealValGreaterThanMessage = '^';
        let deviceOrIdealValLessThanMessage = '^';

        if (useIdealVal) {
            deviceOrIdealVal = 'idealVal';
            deviceOrIdealValGreaterThanMessage += 'Ideal Val';
            deviceOrIdealValLessThanMessage += 'Ideal Val';
        } else {
            deviceOrIdealVal = 'deviceVal';
            deviceOrIdealValGreaterThanMessage += 'Device Val';
            deviceOrIdealValLessThanMessage += 'Device Val';
        }
        deviceOrIdealValGreaterThanMessage += ' must be greater than 0.';
        deviceOrIdealValLessThanMessage += 'must be less than 100.';

        return {
            [deviceOrIdealVal]: { // using this for idealVal and deviceVal checks.
                numericality: {
                    onlyInteger:             true,
                    noStrings:               true, // Sly bugger.
                    greaterThanOrEqualTo:    0,
                    lessThanOrEqualTo:       100,
                    notGreaterThanOrEqualTo: deviceOrIdealValGreaterThanMessage,
                    notLessThanOrEqualTo:    deviceOrIdealValLessThanMessage,
                },
            },
        };
    }

    /**
     * Returns the validation rules
     * for any Device model instance.
     */
    static get validationRules() {
        return {
            ...Model.nameValidationRules,
            ...Device.deviceValValidationRules,
            ...Device.idealValValidationRules,
            deviceTypeID: {
                type:   'string',
                length: {
                    minimum: 1,
                },
                presence: {
                    message: '^Device Type is required', // ^ stops the key from being shoved in automatically.
                },
            },
            idealTransitionType: {
                type:   'string',
                format: {
                    pattern: '^(relative|absolute)$',
                    message: 'can only be relative or absolute',
                },
                presence: true,
            },
            inputLowLimit: {
                numericality: {
                    onlyInteger:             true,
                    greaterThanOrEqualTo:    0,
                    lessThanOrEqualTo:       100,
                    notGreaterThanOrEqualTo: 'must be less than 100',
                    notLessThanOrEqualTo:    'must be greater than 0',
                },
            },
            inputHighLimit: {
                numericality: {
                    onlyInteger:             true,
                    greaterThanOrEqualTo:    0,
                    lessThanOrEqualTo:       100,
                    notGreaterThanOrEqualTo: 'must be less than 100',
                    notLessThanOrEqualTo:    'must be greater than 0',
                },
            },
            outputLowLimit: {
                numericality: {
                    onlyInteger:             true,
                    greaterThanOrEqualTo:    0,
                    lessThanOrEqualTo:       100,
                    notGreaterThanOrEqualTo: 'must be less than 100',
                    notLessThanOrEqualTo:    'must be greater than 0',
                },
            },
            outputHighLimit: {
                numericality: {
                    onlyInteger:             true,
                    greaterThanOrEqualTo:    0,
                    lessThanOrEqualTo:       100,
                    notGreaterThanOrEqualTo: 'must be less than 100',
                    notLessThanOrEqualTo:    'must be greater than 0',
                },
            },
        };
    }

    static get defaultVals() {
        return {
            ...Model.nameDefault,
            deviceVal:            0,
            idealVal:             0,
            idealTransitionType:  'relative',
            idealTransitionSpeed: 200,
            inputLowLimit:        0,
            inputHighLimit:       100,
            outputLowLimit:       0,
            outputHighLimit:      100,
        };
    }

    static get getDefaultKeyToValidate() {
        return Object.keys(Device.defaultVals);
    }

    static get getNonDefaultKeyToValidate() {
        return ['deviceTypeID', 'deviceTypeName'];
    }

    static get getKeysToValidate() {
        return [...Device.getDefaultKeyToValidate, ...Device.getNonDefaultKeyToValidate];
    }

    /**
     * Valides a passed in value as a deviceVal.
     */
    static validatePossibleDeviceVal(deviceValToCheck) {
        return Device.handleValidation({
            deviceVal: deviceValToCheck,
        }, Device.deviceValValidationRules);
    }

    static deviceCheckinTypeAllowsActivation(currCheckinType) {
        return ['sequenceAction', 'intentionalAction', 'systemAction'].indexOf(currCheckinType) !== -1;
    }

    static deviceIsActivated(currDeviceVal, idealVal, idealComparator, previousDeviceVal) {
        if (!Device.isAllowedComparator(idealComparator)) {
            return EPOGlobalUtils.generateNewBasicError(
                'deviceActivationCheckError',
                'Illegal ideal comparator was used.',
            );
        }

        if (idealComparator === 'equals') {
            return (currDeviceVal === idealVal);
        } if (idealComparator === 'equalsTriggerVal') {
            return (currDeviceVal === idealVal);
        } if (idealComparator === 'less') {
            return (currDeviceVal < idealVal);
        } if (idealComparator === 'greater') {
            return (currDeviceVal > idealVal);
        } if (idealComparator === 'change') {
            return (currDeviceVal !== previousDeviceVal);
        } if (idealComparator === 'any') {
            return true; // This feels so dirty.
        }

        return false;
    }

    static getAllowedComparators() {
        return ['equals', 'any', 'change', 'less', 'more', 'equalsTriggerVal'];
    }

    static isAllowedComparator(comparator) {
        return (Device.getAllowedComparators().indexOf(comparator) !== -1);
    }

    static getAllowedDeviceTransitionTypes() {
        // definitions here: // https://604labs.atlassian.net/wiki/spaces/TECH/pages/418217996/Device+Transition+Types
        return [
            'constant',
            'relative',
        ];
    }

    static isUsingUptoDateReceiverFirmware(tempFirmwareVersion) {
        return (tempFirmwareVersion == Device.upToDateReceiverFirmware);
    }

    static isUsingUptoDateTriggerFirmware(tempFirmwareVersion) {
        return (tempFirmwareVersion == Device.upToDateTriggerFirmware);
    }

    static deviceCanBeUsedByProject(deviceKey, projectKey, allowedProjectKeys) {
        if (!allowedProjectKeys[(projectKey)]) {
            return EPOGlobalUtils.generateNewBasicError(
                'deviceProjectAssociationError',
                `A device (key: ${deviceKey}) does not have access to the project (key: ${projectKey})`,
            );
        }
        return true;
    }

    fillWithDefaultDeviceData() {
        this.setPropertiesForGivenJSONBlob(Device.defaultVals);
    }

    preProcessDefaultData() {
        this.fillWithDefaultDeviceData();
    }

    /**
     * Valides this device.
     */
    validate() {
        return Device.handleValidation(this.getFilledInDataBlobToValidate(), Device.validationRules);
    }

    getFilledInDataBlobToValidate() {
        // This .apply() business lets us pass in an array to handle all of the keys that we want to make sure
        // we have good values for. Seems like default values should fit that bill.
        // _.pick(objectWithData, keyYouWantToCheck*);
        return _.pick(this, ...Device.getKeysToValidate);
    }

    validateAttemptedIdealVal() {
        const attemptedIdealValErrorData = Device.validatePossibleDeviceVal(this.attemptedIdealVal);
        return attemptedIdealValErrorData.deviceVal.isValid;
    }

    validateAttemptedDeviceVal() {
        const attemptedDeviceValErrorData = Device.validatePossibleDeviceVal(this.attemptedDeviceVal);
        return attemptedDeviceValErrorData.deviceVal.isValid;
    }

    resetAttemptedIdealVal() {
        this.attemptedIdealVal = this.idealVal;
        this.attemptedIdealValErrorMessage = null;
    }

    resetAttemptedDeviceVal() {
        this.attemptedDeviceVal = this.deviceVal;
        this.attemptedDeviceValErrorMessage = null;
    }

    getIdealValErrorMessageOrNull() {
        if (this.validateAttemptedIdealVal()) {
            return null;
        }
        const attemptedIdealValErrorData = Device.validatePossibleDeviceVal(this.attemptedIdealVal);
        return attemptedIdealValErrorData.deviceVal.errors[0];
    }

    getDeviceValErrorMessageOrNull() {
        if (this.validateAttemptedDeviceVal()) {
            return null;
        }
        const attemptedDeviceValErrorData = Device.validatePossibleDeviceVal(this.attemptedDeviceVal);
        return attemptedDeviceValErrorData.deviceVal.errors[0];
    }

    leftTruncate(keyToTruncate) {
        return this.leftTruncateValOrBlank(this[keyToTruncate]);
    }

    formatTimestampFromPropertyKey(keyToFormat) {
        return formatTimestamp(this[keyToFormat], 'h:mm:ss a - M/D/YYYY');
    }
}

module.exports = Device;
