const _ = require('underscore');

const Database = require('../../functions/db/Database');
const dbFunctions = require('../../functions/db/functions');

const Model = require('./Model.js');
const BackendDevice = require('./backend/BackendDevice');
const FrontendDevice = require('./frontend/FrontendDevice');

class Stage extends Model {
    get lastActivationTimeChanged() {
        return this.isNew || this.lastActivationTime !== this.previousModel.lastActivationTime;
    }

    // TODO remove this
    get availableDevicesForSelectionDropdown() {
        return _.toArray(this.frontendDevicesByDeviceKey);
    }

    get selectedFrontendDeviceArray() {
        // https://underscorejs.org/#where
        return _.where(this.frontendDevicesByDeviceKey, { isSelected: true });
    }

    static get refPath() {
        return 'sequence_items';
    }

    static get allowedTriggerStageDeviceComparisons() {
        return ['less', 'more', 'equals'];
    }

    static get allowedReceiverStageDeviceComparisons() {
        return ['less', 'more', 'equals'];
    }

    static refPathForKey(key) {
        return dbFunctions.refPathForTypeAndKey(Stage.refPath, key);
    }

    static get validationRules() {
        return {
            ...Model.nameValidationRules,
            comparisonData: {
                type:     'object',
                presence: {
                    allowEmpty: false,
                },
            },
        };
    }

    static get defaultVals() {
        return {
            ...Model.nameDefault,
            stageDeviceData:            [],
            frontendDevicesByDeviceKey: {},
        };
    }

    static baseStageDataForDisplay(stageData) {
        const tempDisplayData = {
            stageType:      stageData.stageType,
            name:           stageData.name,
            stageKey:       stageData.stageKey,
            createdAtLocal: stageData.createdAtLocal,
            devicesData:    stageData.devicesData,
            updatedAt:      stageData.updatedAt,
        };

        // Only set if it's there.
        if (stageData.lastActivationTime) {
            tempDisplayData.lastActivationTime = stageData.lastActivationTime;
        }

        return tempDisplayData;
    }

    static triggerStageComparisonAllowsInput(comparisonType) {
        return this.allowedTriggerStageDeviceComparisons.includes(comparisonType);
    }

    static receiverStageComparisonAllowsInput(comparisonType) {
        return this.allowedReceiverStageDeviceComparisons.includes(comparisonType);
    }

    static isReceiverTypeStage(tempStage) {
        return tempStage.stageType === 'receiver';
    }

    static isTriggerTypeStage(tempStage) {
        return tempStage.stageType === 'trigger';
    }

    fillWithDefaultStageData() {
        this.setPropertiesForGivenJSONBlob(Stage.defaultVals);
    }

    preProcessDefaultData() {
        this.fillWithDefaultStageData();
    }

    postProcessDefaultData() {
        if (this.hasAllowedBeforeData) {
            this.previousModel = new Stage(this.beforeData);
        }
    }

    loadDevicesCanBeUsedByProject(projectID) {
        const deviceLoadPromises = [];

        _.mapObject(this.devicesData, (deviceData) => {
            const { deviceKey } = deviceData;
            const tempGetDeviceDataPromise = BackendDevice.canDeviceBeUsedByProject(deviceKey, projectID);
            deviceLoadPromises.push(tempGetDeviceDataPromise);
        });
        return Promise.all(deviceLoadPromises);
    }

    allStageDevicesAreAllowedForProject(projectID) {
        return this.loadDevicesCanBeUsedByProject(projectID).then((deviceCanBeUsedByProjectResults) => {
            let allDevicesAreAllowed = true;

            deviceCanBeUsedByProjectResults.forEach((deviceCanBeUsedByProject) => {
                if (!deviceCanBeUsedByProject) {
                    allDevicesAreAllowed = false;
                }
            });
            return allDevicesAreAllowed;
        });
    }

    generateBaseStageDataToSave() {
        return {
            stageKey:           this.stageKey,
            createdAt:          Database.serverTimestamp,
            createdAtLocal:     this.createdAtLocal,
            updatedAt:          Database.serverTimestamp,
            createdInProjectID: this.projectID,
            allowedProjects:    {
                [this.projectID]: true,
            },
            name:        this.name,
            comparisons: this.comparisonData,
            devicesData: this.devicesData,
        };
    }

    getFrontendDeviceOrNull(deviceKey) {
        const tempDeviceForKeyValidation = FrontendDevice.isValidDeviceKey(deviceKey);
        if (!tempDeviceForKeyValidation) {
            return null;
        }

        let frontendDeviceIfExists = null;
        _.mapObject(this.frontendDevicesByDeviceKey, (currFrontendDevice) => {
            // isNull check just to make it that much faster.
            if (_.isNull(frontendDeviceIfExists) && currFrontendDevice.deviceKey === deviceKey) {
                frontendDeviceIfExists = currFrontendDevice;
            }
        });
        return frontendDeviceIfExists;
    }

    markFrontendDeviceAsSelected(frontendDeviceKey) {
        this.changeFrontendDeviceIsSelected(frontendDeviceKey, true);
    }

    markFrontendDeviceAsNotSelected(frontendDeviceKey) {
        this.changeFrontendDeviceIsSelected(frontendDeviceKey, false);
    }

    changeFrontendDeviceIsSelected(frontendDeviceKey, isSelected) {
        this.frontendDevicesByDeviceKey[frontendDeviceKey].isSelected = isSelected;
    }
}

module.exports = Stage;
