import firebase from 'firebase/app';
import { firebaseAction } from 'vuexfire';
import { auth, db } from '@/firebase';

/* eslint-disable no-unused-vars */
export default {
    login({ commit }) {
        const provider = new firebase.auth.GoogleAuthProvider();
        return auth.signInWithPopup(provider).then((result) => {
            commit('setLocalAccount', result.user);
        }).catch((err) => {
            /* eslint-disable no-console */
            console.error(err);
            /* eslint-enable no-console */
        });
    },

    logout({ commit }) {
        return auth.signOut().then(() => {
            commit('clearLocalAccount');
        });
    },

    getConnectionMonitors({ commit }, data) {
        const { projectKey, callbackFunction } = data;
        const connectionMonitors = db.ref('/connection_monitors');
        // `/projects_can_use_connection_monitors/${projectKey}/connectionMonitorKeys`); // actual
        return connectionMonitors.on('value', (snapshot) => callbackFunction(snapshot.toJSON()));
    },

    // ref needed because we only need to listen long enough for a new item to arrive and then stop listening.
    getProjectsCanuseDeviceRef({ commit }, projectKey) {
        return db.ref(`/projects_can_use_device/${projectKey}/deviceKeys`);
    },

    // ref needed because we only need to listen long enough for a new item to arrive and then stop listening.
    getProjectsCanuseConnectionRef({ commit }, projectKey) {
        return db.ref(`/projects_can_use_connection/${projectKey}/deviceKeys`);
    },

    bindLocalUserToUserUID: firebaseAction(({ bindFirebaseRef }, userID) => bindFirebaseRef(
        'user',
        db.ref(`users/${userID}`),
    )),

    bindLocalProjectToProjectKey: firebaseAction(({ bindFirebaseRef }, projectKey) => bindFirebaseRef(
        'currentProject',
        db.ref(`projects/${projectKey}`),
    )),

    bindCurrentProjectDevicesToProjectKey: firebaseAction(({ bindFirebaseRef }, projectKey) => bindFirebaseRef(
        'currentProjectDevices',
        db.ref(`projects_can_use_device/${projectKey}/deviceKeys`),
    )),

    bindCurrentProjectConnectionMonitorsToProjectKey: firebaseAction(({ bindFirebaseRef }, projectKey) => bindFirebaseRef(
        'currentProjectConnectionMonitors',
        db.ref(`projects_can_use_connectionMonitors/${projectKey}/deviceKeys`),
    )),

    bindCurrentProjectConnectionToProjectKey: firebaseAction(({ bindFirebaseRef }, projectKey) => bindFirebaseRef(
        'currentProjectConnections',
        db.ref(`projects_can_use_connections/${projectKey}/connectionKeys`),
    )),

    updateProjectBindingsToKey({ dispatch }, projectKey) {
        return Promise.all([
            dispatch('bindLocalProjectToProjectKey', projectKey),
            dispatch('bindCurrentProjectDevicesToProjectKey', projectKey),
            dispatch('bindCurrentProjectConnectionMonitorsToProjectKey', projectKey),
            dispatch('bindCurrentProjectConnectionToProjectKey', projectKey),
        ]);
    },
};
