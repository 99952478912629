import LoginComponent from '@c/auth/LoginComponent.vue';
import UserComponent from '@c/auth/UserComponent.vue';
import HomeComponent from '@c/authenticated/HomeComponent.vue';

export default [
    {
        name:      'user',
        path:      '/user',
        component: UserComponent,
    },
    {
        name:      'home',
        path:      '/home',
        component: HomeComponent,
    },
    {
        name:     'landing',
        path:     '',
        redirect: { name: 'login' },
    },
    {
        name:      'login',
        path:      '/login',
        component: LoginComponent,
    },

];
