<template>
    <b-card
        class="project-card"
        :header-bg-variant="colorVariants.header"
        :header-text-variant="colorVariants.headerText"
        :border-variant="colorVariants.border"
        :header="cardHeader"
    >
        <b-row>
            <b-col>
                <p>Name: {{ project.name }}</p>
                <p>Key: {{ project.key }}</p>
            </b-col>
            <b-col>
                <b-button
                    v-if="!projectIsCurrentProject"
                    variant="primary"
                    @click="switchActiveProject(project.key)"
                >
                    Make Active Project
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        props: {
            project: {
                type: Object,
                default() {
                    return {};
                },
            },
        },

        computed: {
            ...mapGetters('user', {
                currentProjectKey: 'getCurrentProjectKey',
            }),
            projectIsCurrentProject() {
                return this.project.key === this.currentProjectKey;
            },
            colorVariants() {
                if (!this.projectIsCurrentProject) {
                    return {
                        header:     '',
                        border:     '',
                        headerText: '',
                    };
                }
                return {
                    header:     'primary',
                    border:     'primary',
                    headerText: 'white',
                };
            },
            cardHeader() {
                if (this.projectIsCurrentProject) {
                    return `${this.project.name} (Currently Active Project)`;
                }
                return this.project.name;
            },
        },

        methods: {
            ...mapActions('user', {
                switchActiveProject: 'updateProjectBindingsToKey',
            }),
        },
    };
</script>
