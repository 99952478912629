import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire';

import user from './user/module';

Vue.use(Vuex);

const store = new Vuex.Store({
    mutations: {
        ...vuexfireMutations,
    },
    modules: {
        user,
    },
});

export default store;
