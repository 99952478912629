<template>
    <div>
        <div
            v-if="deviceData.item.deviceUseBoolean"
        >
            <b-input-group
                size="sm"
                prepend="Set to:"
            >
                <b-button
                    size="sm"
                    @click="validateAndModifyIdealValFromFieldIfValid(deviceData.item);"
                >
                    Off
                </b-button>
                <b-button
                    size="sm"
                    @click="validateAndModifyIdealValFromFieldIfValid(deviceData.item);"
                >
                    On
                </b-button>
            </b-input-group>
        </div>
        <div v-else>
            <b-input-group
                size="sm"
                prepend="Set value to:"
            >
                <b-form-input
                    id="idealValInput"
                    :value="deviceData.item.attemptedIdealVal"
                    type="number"
                    class="w-25 form-control"
                    placeholder="Enter 0-100"
                    min="0"
                    max="100"
                    @keyup.native="setSanitizedIdealVal(deviceData.item, $event.target.value)"
                    @blur.native="clearAttemptedIdealValIfEmpty(deviceData.item, $event.target.value)"
                />
                <b-input-group-append>
                    <b-button disabled>
                        %
                    </b-button>
                    <b-button
                        size="sm"
                        @click="validateAndModifyIdealValFromFieldIfValid(deviceData.item);"
                    >
                        Set I
                    </b-button>
                </b-input-group-append>
            </b-input-group>
        </div>
        <div v-if="deviceData.item.deviceUsePercent">
            <b-button
                v-for="shortcutVal in shortcutButtonVals"
                :key="shortcutVal"
                class="shortcutButton"
                @click="executePercentIdealShortcutButton(deviceData.item.deviceKey, shortcutVal);"
            >
                {{ shortcutVal }}
            </b-button>
        </div>
        <b-col
            v-if="(!deviceData.item.hasValidIdealVal)"
            v-show="!deviceData.item.hasValidAttemptedIdealVal"
            class="text-danger"
        >
            {{ deviceData.item.attemptedIdealValErrorMessage }}
        </b-col>
    </div>
</template>

<script>
    import { _ } from 'vue-underscore';
    import { getCloudFunctionByName } from '../firebase';

    export default {
        props: {
            deviceData: {
                type:    Object,
                default: () => {},
            },
        },

        methods: {
            setSanitizedIdealVal(device, inputValue) {
                if (_.isString(inputValue) && inputValue.length !== 0) {
                    device.attemptedIdealVal = parseInt(inputValue, 10);
                }
            },
            executePercentIdealShortcutButton(deviceKey, shortcutVal) {
                const currDevice = this.getDeviceByKeyFromLocalStore(deviceKey);
                currDevice.attemptedIdealVal = shortcutVal;
                this.validateAndModifyIdealValFromFieldIfValid(currDevice);
            },

            validateAndModifyIdealValFromFieldIfValid(device) {
                device.hasValidAttemptedIdealVal = device.validateAttemptedIdealVal();
                if (device.hasValidAttemptedIdealVal) {
                    this.updateIdealVal(device);
                }
                device.attemptedIdealValErrorMessage = device.getIdealValErrorMessageOrNull();
                this.showErrorMessageForIdealVal(device, false);
            },

            showErrorMessageForIdealVal(device, blurrHappened) {
                const blurred = blurrHappened;
                const deviceValisGood = device.hasValidAttemptedIdealVal;

                if (blurred && !deviceValisGood) {
                    return true;
                }
                return false;
            },

            updateIdealVal(device) {
                const changeIdealValFunction = getCloudFunctionByName('deviceIdealValCheckIn');
                const data = {
                    currDeviceID:       device.deviceKey,
                    idealVal:           device.attemptedIdealVal,
                    localEditTimestamp: new Date().getTime(),
                };
                changeIdealValFunction(data).then((result) => {
                    if (result.data && result.data.error) {
                        // eslint-disable-next-line no-console
                        console.error(result.data);
                    }
                });
            },

            clearAttemptedIdealValIfEmpty(device, inputValue) {
                // should reset to actual idealVal if we blur on an empty input
                if (_.isString(inputValue) && inputValue.length === 0) {
                    device.resetAttemptedIdealVal();
                }
            },
        },
    };
</script>
