<template>
    <div
        :class="animatedClass"
        class="click-target"
        @click="copyString()"
    >
        {{ label }}
    </div>
</template>

<script>
    import { copyString } from '../utils/strings';

    export default {
        props: {
            stringToCopy: {
                type:    String,
                default: '',
            },
            label: {
                type:    String,
                default: '',
            },
        },
        data() {
            return {
                animated: false,
            };
        },
        computed: {
            animatedClass() {
                if (this.animated) {
                    return 'blink-background-animation';
                }
                return '';
            },
        },
        methods: {
            copyString() {
                this.animated = true;
                setTimeout(() => {
                    this.animated = false;
                }, 350);
                return copyString(this.stringToCopy);
            },
        },
    };
</script>

<style scoped>
    .click-target {
        margin-bottom: 30%;
        cursor: pointer;
    }
    .blink-background-animation {
        -webkit-animation: background-animation 250ms 1; /* Safari 4+ */
        -moz-animation:    background-animation 250ms 1; /* Fx 5+ */
        -o-animation:      background-animation 250ms 1; /* Opera 12+ */
        animation:         background-animation 250ms 1; /* IE 10+, Fx 29+ */
        animation-timing-function: ease-in;
    }
    @-webkit-keyframes background-animation {
        0%   { background-color: transparent; }
        100% { background-color: #5cb85c; }
    }
    @-moz-keyframes background-animation {
        0%   { background-color: transparent; }
        100% { background-color: #5cb85c; }
    }
    @-o-keyframes background-animation {
        0%   { background-color: transparent; }
        100% { background-color: #5cb85c; }
    }
    @keyframes background-animation {
        0%   { background-color: transparent; }
        100% { background-color: #5cb85c; }
    }
</style>
