const _ = require('underscore');

const Device = require('../Device');

class FrontendDevice extends Device {
    get formattedIdealTransitionSpeed() {
        if (_.isUndefined(this.idealTransitionSpeed)) {
            return '-';
        }
        return FrontendDevice.formatTransitionSpeed(this.idealTransitionSpeed);
    }

    get formattedDeviceKey() {
        return this.leftTruncateValOrBlank('deviceKey');
    }

    get formattedStreamingKey() {
        return this.leftTruncateValOrBlank('deviceStreamingKey');
    }

    get formattedReroutedToConnectionMonitorKey() {
        return this.leftTruncateValOrBlank('reroutedToConnectionMonitorKey');
    }

    // Needed for bootstrap.
    get disabled() {
        return this.isSelected;
    }

    get optionsForDeviceTriggerComparisonType() {
        if (this.isBooleanDevice) {
            return FrontendDevice.allowedComparisonTypeJSONBlobForBooleanDevices;
        }
        return FrontendDevice.allowedComparisonTypeJSONBlobForPercentDevices;
    }

    static formatTransitionSpeed(timestamp) {
        return `${timestamp}ms`;
    }

    // Calling statically because we are looking at a UI value.
    static isValidDeviceKey(deviceKey) {
        let isValidAndAvailableDeviceKey = true;
        if (!_.isString(deviceKey)) {
            isValidAndAvailableDeviceKey = false;
        } else if (!deviceKey.length) {
            isValidAndAvailableDeviceKey = false;
        } else if (deviceKey === 'default') {
            isValidAndAvailableDeviceKey = false;
        }

        if (!isValidAndAvailableDeviceKey) {
            return false;
        }
        return true;
    }

    static filterDeviceForSelectedTriggerDevice(deviceDetails) {
        const tempBlob = {
            name:           deviceDetails.name,
            deviceTypeName: deviceDetails.deviceTypeName,
        };
        if (deviceDetails.deviceUseBoolean === true) {
            tempBlob.deviceUseBoolean = deviceDetails.deviceUseBoolean;
        }
        if (deviceDetails.deviceUsePercent === true) {
            tempBlob.deviceUsePercent = deviceDetails.deviceUsePercent;
        }
        return tempBlob;
    }

    static get allowedComparisonTypeJSONBaseBlob() {
        return [
            { value: 'equals', text: 'Equals' },
            { value: 'changed', text: 'Changed val' },
            { value: 'any', text: 'Any val' },
        ];
    }

    static get allowedComparisonTypeJSONPercentDeviceSpecificBlob() {
        return [
            { value: 'less', text: 'Less than' },
            { value: 'more', text: 'More than' },
        ];
    }

    static get allowedComparisonTypeJSONBlobForPercentDevices() {
        const allowedBase = FrontendDevice.allowedComparisonTypeJSONBaseBlob;
        const allowedPercentSpecific = FrontendDevice.allowedComparisonTypeJSONPercentDeviceSpecificBlob;

        return [...allowedBase, ...allowedPercentSpecific];
    }

    static get allowedComparisonTypeJSONBlobForBooleanDevices() {
        return this.allowedComparisonTypeJSONBaseBlob;
    }

    static get defaultFrontendDeviceVals() {
        return {
            hasValidAttemptedIdealVal:      null,
            hasValidAttemptedDeviceVal:     null,
            attemptedIdealValErrorMessage:  null,
            attemptedDeviceValErrorMessage: null,
            attemptedDeviceVal:             null,
            attemptedIdealVal:              null,
            localEditedTimestamp:           (new Date().getTime()),
            isSelected:                     false,
        };
    }

    fillWithDefaultFrontendDeviceData(deviceData) {
        this.setPropertiesForGivenJSONBlob(FrontendDevice.defaultFrontendDeviceVals);
        this.text = deviceData.name; // for dropdowns
        this.value = deviceData.deviceKey; // for dropdowns
    }

    preProcessDefaultData(deviceData) {
        this.fillWithDefaultDeviceData();
        this.fillWithDefaultFrontendDeviceData(deviceData);
    }

    postProcessDefaultData() {
        this.hasValidAttemptedIdealVal = this.validateAttemptedIdealVal();
        this.hasValidAttemptedDeviceVal = this.validateAttemptedDeviceVal();

        if (_.isNull(this.attemptedDeviceVal)) {
            this.attemptedDeviceVal = this.deviceVal;
        }

        if (_.isNull(this.attemptedIdealVal)) {
            this.attemptedIdealVal = this.idealVal;
        }
    }
}

module.exports = FrontendDevice;
