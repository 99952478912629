<template>
    <div>
        <b-table
            ref="connectionsTable"
            responsive
            :fields="fields"
            :items="getConnectionsTableArray()"
            show-empty
            :empty-text="noConnectionsText"
        >
            <template #cell(actions)="data">
                <b-button
                    :disabled="!data.item.isDisabled"
                    @click="enableConnection(data.item.connectionKey)"
                >
                    Enable
                </b-button>
                <b-button
                    :disabled="data.item.isDisabled"
                    @click="disableConnection(data.item.connectionKey)"
                >
                    Disable
                </b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { getCloudFunctionByName } from '../firebase';

    export default {
        data() {
            return {
                fields: [
                    // A virtual column that doesn't exist in items
                    {
                        key:      'connectionKey',
                        label:    'Key',
                        sortable: true,
                    },
                    {
                        key:      'name',
                        label:    'Name',
                        sortable: true,
                    },
                    {
                        key:      'sequenceKey',
                        label:    'Sequence Key',
                        sortable: true,
                    },
                    {
                        key:      'maxLoopCount',
                        label:    'Loops',
                        sortable: true,
                    },
                    {
                        key:   'actions',
                        label: 'Actions',
                    },
                ],
            };
        },
        computed: {
            ...mapGetters('user', {
                currentProjectKey:                    'getCurrentProjectKey',
                someCurrentFrontendConnectionsObject: 'getCurrentProjectFrontendConnectionsObject',
            }),
            noConnectionsText() {
                return 'There are no connections.';
            },
        },
        methods: {
            getConnectionsTableArray() {
                return Object.values(this.someCurrentFrontendConnectionsObject);
            },
            enableConnection(key) {
                this.toggleConnection(true, key);
            },
            disableConnection(key) {
                this.toggleConnection(false, key);
            },
            toggleConnection(setToEnabled, key) {
                const cloudFunctionName = 'disableEnableConnection';
                const toggleConnectionCloudFunction = getCloudFunctionByName(cloudFunctionName);

                toggleConnectionCloudFunction({
                    connectionKey: key,
                    setToEnabled,
                }).then((result) => {
                    if (result.data && result.data.error) {
                        // eslint-disable-next-line no-console
                        console.error(result.data);
                    }
                });
            },
        },
    };
</script>
