const admin = require('firebase-admin');
const _ = require('underscore');

const dbFunctions = require('./functions');

const EPOGlobalUtils = require('../604GlobalUtils');
const globalApp = require('../app');

class Database {
    constructor(path = null, app = null) {
        let newApp = app;
        if (_.isNull(newApp)) {
            newApp = globalApp;
        }
        this.app = newApp;
        this.path = path;

        // TODO checks in other functions to see if .ref is there. -JS 1/14/2021
        if (!_.isNull(path)) {
            const db = this.app.database();
            this.ref = db.ref(path);
        }
    }

    get getKeyFromRef() {
        return this.ref.key;
    }

    get getParentRef() {
        return this.ref.parent;
    }

    static get serverTimestamp() {
        return admin.database.ServerValue.TIMESTAMP;
    }

    // The push function gives you a chronological id, so we know it'll be unique.
    // DB reference isn't actually used for anything.
    static getNewStreamingKey() {
        return Database.getNewKeyForPath('/streaming_key_generator/');
    }

    // The push function gives you a chronological id, so we know it'll be unique.
    // DB reference isn't actually used for anything.
    static getAllDeviceDataByDeviceKey(deviceKey) {
        return Database.getValueOrNullByPath(`/devices/${deviceKey}`);
    }

    // @brendan, this feels dirty sinc we have it nonstatic also.
    static getValueOrNullByPath(path) {
        return Database.getNewDBAtPath(path).getValueOrNull();
    }

    static getNewKeyForPath(path) {
        const tempRef = Database.getNewDBAtPath(path).ref.push();
        return tempRef.key;
    }

    static getNewKeyFromPushingToRefPath(path) {
        return Database.getNewKeyForPath(path).key;
    }

    static removeValueAtPath(path) {
        return Database.getNewRefAtPath(path).remove();
    }

    static setValueAtPath(path, value) {
        return Database.getNewRefAtPath(path).set(value);
    }

    static updateValueAtPath(path, value) {
        return Database.getNewRefAtPath(path).update(value);
    }

    static getNewDBAtPath(path) {
        return (new Database(path));
    }

    static getNewRefAtPath(path) {
        return Database.getNewDBAtPath(path).ref;
    }

    static pushValueAtPath(path, dataToPush) {
        return Database.getNewRefAtPath(path).push(dataToPush);
    }

    static getValueFromPathThatMatchesKey(path, key, value, limitToOne = true) {
        const baseDB = new Database(path);
        const baseRef = baseDB.ref;

        let limitedResultRef;
        if (limitToOne) {
            limitedResultRef = baseRef.orderByChild(key)
                .equalTo(value)
                .limitToFirst(1);
        } else {
            limitedResultRef = baseRef.orderByChild(key)
                .equalTo(value);
        }
        baseDB.changeRef(limitedResultRef);

        return baseDB.getValueOrNull();
    }

    static projectIncludesUser(uid, currProjectID) {
        return Database.getValueOrNullByPath(`users/${uid}/projects/${currProjectID}`);
    }

    static setUpdateSequenceItemLastActivatedTimeAndData(path, triggerSequenceItemJustActivatedData) {
        return Database.updateValueAtPath(path, {
            lastActivationTime: Database.serverTimestamp,
            lastActivationData: triggerSequenceItemJustActivatedData,
        });
    }

    static deviceIsInProject(projectID, deviceKey) {
        const tempProjectsCanUseVar = dbFunctions.getProjectsCanUseStringByTypeAndKey('device', deviceKey, projectID);
        return Database.getValueOrNullByPath(tempProjectsCanUseVar)
            .then((deviceData) => {
                if (!deviceData) {
                    // eslint-disable-next-line no-console
                    console.error('No device found for that project.');
                    return EPOGlobalUtils.generateNewBasicError(
                        'deviceNotInProjectError',
                        'No device found for that project.',
                    );
                }
                return true;
            });
    }

    static saveNewDevicesIdealVals(updatedStageDevicesJSONBlob) {
        return Database.updateValueAtPath('/', updatedStageDevicesJSONBlob);
    }

    static setStageFinishedTime(stagePath) {
        // Only do something if the currVal is null.
        // eslint-disable-next-line consistent-return
        return Database.getNewRefAtPath(stagePath).transaction((currStageFinished) => {
            // Set the timestamp if we don't have it already.
            if (_.isNull(currStageFinished)) {
                return Database.serverTimestamp;
            }
        });
    }

    static setFinishedStagesToNull(path) {
        return Database.updateValueAtPath(path, {
            finishedStages:          null,
            finishedStagesResetTime: Database.serverTimestamp,
        });
    }

    static setNewLastStageChangeTime(path) {
        return Database.updateValueAtPath(path, {
            lastStageChangeTime: Database.serverTimestamp,
        });
    }

    static handleDeviceRestartCheckin(devicePath, deviceStreamingKey, checkinType, firmwareVersion) {
        const handleDeviceRestartCheckinPromises = [];

        const removeRestartNowPromise = Database.updateValueAtPath('/streaming_device/', {
            [deviceStreamingKey]: null,
        });
        handleDeviceRestartCheckinPromises.push(removeRestartNowPromise);

        const restartCheckinPromise = Database.updateValueAtPath(devicePath, {
            deviceStreamFirmware: firmwareVersion,
            lastCheckinTime:      Database.serverTimestamp,
            lastCheckinType:      checkinType,
        });
        handleDeviceRestartCheckinPromises.push(restartCheckinPromise);

        return Promise.all(handleDeviceRestartCheckinPromises);
    }

    static mapJSONBlobToJSONPathBlob(basePath, currJSON) {
        const updatedJSON = {};
        _.mapObject(currJSON, (currVal, currKey) => {
            updatedJSON[`${basePath}/${currKey}`] = currVal;
        });
        return updatedJSON;
    }

    static finishConnectionMonitor(basePath, isSuccessful) {
        return Database.updateValueAtPath(basePath, {
            finishedSuccesfully: isSuccessful,
            finishedTime:        Database.serverTimestamp,
        });
    }

    static updateProjectsCanUseSequence(allowedProjectsData, sequenceKey, filteredData) {
        const setSequenceProjectKeysDataPromises = [];
        _.mapObject(allowedProjectsData, (tempProjectKeyData, tempProjectKey) => {
            const tempSetSequencePromise = Database.updateReferenceByPathIfUpdatedAtIsNewer(
                dbFunctions.getProjectsCanUseStringByTypeAndKey('sequence', sequenceKey, tempProjectKey),
                filteredData,
            );
            setSequenceProjectKeysDataPromises.push(tempSetSequencePromise);
        });
        return Promise.all(setSequenceProjectKeysDataPromises);
    }

    static updateProjectsCanUseDevice(allowedProjectsData, deviceKey, filteredData) {
        const updateProjectsCanUseDevicePromises = [];
        _.mapObject(allowedProjectsData, (tempProjectKeyData, tempProjectKey) => {
            const tempUpdateDevicePromise = Database.updateReferenceByPathIfUpdatedAtIsNewer(
                dbFunctions.getProjectsCanUseStringByTypeAndKey('device', deviceKey, tempProjectKey),
                filteredData,
            );
            updateProjectsCanUseDevicePromises.push(tempUpdateDevicePromise);
        });
        return Promise.all(updateProjectsCanUseDevicePromises);
    }

    static updateProjectsCanUseSequenceItem(allowedProjectsData, sequenceItemKey, filteredData) {
        const setSequenceItemProjectKeysDataPromises = [];
        _.mapObject(allowedProjectsData, (tempProjectKeyData, tempProjectKey) => {
            const tempSetSequenceItemPromise = Database.updateReferenceByPathIfUpdatedAtIsNewer(
                dbFunctions.getProjectsCanUseStringByTypeAndKey('receiverStage', sequenceItemKey, tempProjectKey),
                filteredData,
            );
            setSequenceItemProjectKeysDataPromises.push(tempSetSequenceItemPromise);
        });
        return Promise.all(setSequenceItemProjectKeysDataPromises);
    }

    static updateProjectsCanUseConnection(allowedProjectsData, connectionKey, filteredData) {
        const setConnectionProjectKeysDataPromises = [];
        _.mapObject(allowedProjectsData, (tempProjectKeyData, tempProjectKey) => {
            const tempSetConnectionPromise = Database.updateReferenceByPathIfUpdatedAtIsNewer(
                dbFunctions.getProjectsCanUseStringByTypeAndKey('connection', connectionKey, tempProjectKey),
                filteredData,
            );
            setConnectionProjectKeysDataPromises.push(tempSetConnectionPromise);
        });
        return Promise.all(setConnectionProjectKeysDataPromises);
    }

    static setProjectCanUseConnectionMonitorData(connectionMonitorKey, tempProjectKey, filteredDetails) {
        return Database.updateReferenceByPathIfUpdatedAtIsNewer(
            dbFunctions.getProjectsCanUseStringByTypeAndKey('connectionMonitor', connectionMonitorKey, tempProjectKey),
            filteredDetails,
        );
    }

    static updateProjectsCanUseConnectionMonitor(connectionMonitorKey, connectionKey, filteredData) {
        return Database.getValueOrNullByPath(`connections/${connectionKey}/allowedProjects/`).then((allowedProjectsData) => {
            if (_.isNull(allowedProjectsData)) {
                return true; // end it. should never happen, but still.
            }

            const setProjectCanUseConnectionMonitorPromises = [];
            // tempProjectKeyData here is just a bool.
            _.mapObject(allowedProjectsData, (tempProjectKeyData, tempProjectKey) => {
                const tempSetMonitorPromise = Database.setProjectCanUseConnectionMonitorData(
                    connectionMonitorKey,
                    tempProjectKey,
                    filteredData,
                );
                setProjectCanUseConnectionMonitorPromises.push(tempSetMonitorPromise);
            });

            return Promise.all(setProjectCanUseConnectionMonitorPromises);
        });
    }

    static incrementLoopCount(path) {
        const currConnectionMonitorLoopCountRef = Database.getNewRefAtPath(path);
        return currConnectionMonitorLoopCountRef.transaction((currVal) => (currVal || 0) + 1);
    }

    static updateDeviceIdealValStats(devicePath, newIdealVal, checkinType, idealTransitionSpeed) {
        return Database.updateDeviceData(
            devicePath,
            Database.generateUpdateIdealValStatsJSON(newIdealVal, checkinType, idealTransitionSpeed),
        );
    }

    static updateDeviceValStats(devicePath, deviceVal, checkinType, useIdealValStatsToo, idealTransitionSpeed) {
        const tempUpdateDeviceValStatsJSON = Database.generateUpdateDeviceValStatsJSON(deviceVal, checkinType);

        // intentional checkins do this because they're an ultimate source of truth.
        // _.extend(parent, child);
        if (useIdealValStatsToo) {
            _.extend(
                tempUpdateDeviceValStatsJSON,
                Database.generateUpdateIdealValStatsJSON(deviceVal, checkinType, idealTransitionSpeed),
            );
        }
        return Database.updateDeviceData(devicePath, tempUpdateDeviceValStatsJSON);
    }

    static updateDeviceData(path, deviceData) {
        return Database.updateValueAtPath(path, deviceData);
    }

    static generateUpdateIdealValStatsJSON(idealVal, checkinType, idealTransitionSpeed) {
        return {
            idealVal,
            idealTransitionSpeed,
            idealValUpdatedAt:    Database.serverTimestamp,
            lastCheckinTime:      Database.serverTimestamp,
            lastCheckinType:      checkinType,
            lastIdealCheckinType: checkinType,
        };
    }

    static generateUpdateDeviceValStatsJSON(deviceVal, checkinType) {
        return {
            deviceVal,
            lastDeviceValType:  checkinType,
            deviceValUpdatedAt: Database.serverTimestamp,
            lastCheckinTime:    Database.serverTimestamp,
            lastCheckinType:    checkinType,
        };
    }

    static nodeHasBeenDeleted(snapshot) {
        return !snapshot.after.exists();
    }

    static changeIsFromUpdatedAtTimestamp(snapshot) {
        // if it's new, we want it to continue
        if (!snapshot.before.exists()) {
            return false;
        }

        // if we've just deleted it, we want that to trickle out too.
        if (Database.nodeHasBeenDeleted(snapshot)) {
            return false;
        }

        const beforeSnapshotData = snapshot.before.val();
        const afterSnapshotData = snapshot.after.val();

        // If you remove the `updatedAt` property from each and they're the same,
        // we know that the updatedAt was the delta.
        delete beforeSnapshotData.updatedAt;
        delete afterSnapshotData.updatedAt;

        return _.isEqual(beforeSnapshotData, afterSnapshotData);
    }

    static verifyDeviceByDeviceKeyAndGetDataIfExists(deviceKey) {
        return Database.getValueFromPathThatMatchesKey('devices', 'deviceKey', deviceKey);
    }

    static getverifyDeviceByStreamingKeyAndGetDataIfExists(streamingKey) {
        return Database.getValueFromPathThatMatchesKey('devices', 'deviceStreamingKey', streamingKey);
    }

    static updateReferenceByPathIfUpdatedAtIsNewer(
        refString,
        incomingFilteredData,
    ) {
        return Database.updateReferenceByPathIfComparatorAtIsNewer(refString, incomingFilteredData, 'updatedAt');
    }

    static updateReferenceByPathIfComparatorAtIsNewer(
        projectsCanUsePathForEntityIfItExists,
        incomingFilteredData,
        compareKey,
    ) {
        if (!_.isObject(incomingFilteredData)) {
            return true;
        }

        const updateReferenceDB = new Database(projectsCanUsePathForEntityIfItExists);
        // Returns true to keep things going or updates the DB if the new data is actually newer.
        // This is here because there was a race condition and old data was replacing new data.
        return updateReferenceDB.getValueOrNull().then((existingData) => {
            const parentRef = updateReferenceDB.getParentRef;
            const parentKey = projectsCanUsePathForEntityIfItExists.split('/').pop();
            const updateData = {
                [parentKey]: incomingFilteredData,
            };

            const hasNoExistingData = _.isNull(existingData);
            // Entity is a new one
            if (hasNoExistingData) {
                return Database.updateRefWithData(parentRef, updateData);
            }

            const currEntityKey = Object.keys(updateData)[0]; // The blob always has its key first, then the data.
            const entityHasBeenDeleted = _.isEmpty(updateData[currEntityKey]);
            if (entityHasBeenDeleted) {
                return Database.updateRefWithData(parentRef, updateData);
            }

            // Make sure that we have prior and new keys to compare
            const currAndIncomingDataHaveValueForKey = (
                !_.isUndefined(existingData[compareKey])
                && !_.isUndefined(incomingFilteredData[compareKey])
            );
            if (!currAndIncomingDataHaveValueForKey) {
                return true;
            }

            // Make sure that we have prior and new keys to compare
            const existingValueIsBiggerThanNewValue = existingData[compareKey] > incomingFilteredData[compareKey];
            if (existingValueIsBiggerThanNewValue) {
                return true;
            }

            // Using the ref in case we're deleting something and so we don't have to shuffle more keys around
            return Database.updateRefWithData(parentRef, updateData);
        });
    }

    static updateRefWithData(ref, data) {
        return ref.update(data);
    }

    static updateListeningConnectionMonitor(
        connectionMonitorKey,
        sequenceItemKey,
        deviceKey,
        filteredDeviceData,
        deleteReferencesOnDevice,
    ) {
        if (!connectionMonitorKey || !sequenceItemKey || !deviceKey) {
            return true; // Keep things moving in case of an emergency.
        }

        const longAssDeviceConnectionMonitorRefString = `${''
        + '/connection_monitors/'}${connectionMonitorKey
        }/sequenceItems/${sequenceItemKey
        }/devicesData/${deviceKey}`;

        const connectionMonitorDeviceDatabase = new Database(longAssDeviceConnectionMonitorRefString);
        return connectionMonitorDeviceDatabase.getValueOrNull().then((deviceInConnectionMonitorData) => {
            // must have gotten deleted. Let's delete the reference on the device so we stop meeting here like this.
            if (_.isNull(deviceInConnectionMonitorData)) {
                // We don't always do this because this same function is used for the current CM and prior CM.
                if (deleteReferencesOnDevice) {
                    return Database.updateValueAtPath(`/devices/${deviceKey}`, {
                        idealConnectionMonitorKey: null,
                        idealSequenceItemKey:      null,
                        idealStageNumber:          null,
                    });
                }
                return true;
            }

            return connectionMonitorDeviceDatabase.setValueOnRef(filteredDeviceData);
        });
    }

    static updateDeletedTimestamp(path, uid) {
        return Database.updateValueAtPath(path, {
            timeDeleted:  Database.serverTimestamp,
            deletedByUID: uid,
        });
    }

    static saveNewDevice(devicePath, deviceJSONToSave) {
        return Database.setValueAtPath(devicePath, deviceJSONToSave);
    }

    static updateListeningStages(deviceKey, filteredDeviceData) {
        if (!deviceKey) {
            return true; // Keep things moving in case of an emergency.
        }

        const tempDB = new Database('/sequence_items/');
        return tempDB.ref
            .orderByChild(`devicesData/${deviceKey}/deviceKey`)
            .equalTo(deviceKey)
            .once('value')
            .then((listeningSequenceItemsSnapshot) => {
                if (!listeningSequenceItemsSnapshot.exists()) {
                    return true;
                }

                const updateListeningStagesPromises = [];
                listeningSequenceItemsSnapshot.forEach((listeningSequenceItemSnapshot) => {
                    // ref is at sequence_items/{sequenceItemKey}
                    const tempUpdateSequenceItemReceiverDevicePromise = listeningSequenceItemSnapshot.ref.update({
                        [(`devicesData/${deviceKey}`)]: filteredDeviceData,
                    });
                    updateListeningStagesPromises.push(tempUpdateSequenceItemReceiverDevicePromise);
                });
                return Promise.all(updateListeningStagesPromises);
            });
    }

    static updateOrRemoveDeviceStreamingEndpoint(deviceStreamingKey, filteredData) {
        return Database.updateValueAtPath('streaming_device/', {
            [(deviceStreamingKey)]: filteredData,
        });
    }

    static saveNewUpdatedAtByModelAndKey(modelRefPath, key) {
        return Database.setValueAtPath(dbFunctions.refPathForUpdatedAtWithTypeAndKey(modelRefPath, key), Database.serverTimestamp);
    }

    getValueOrNull() {
        return this.ref.once('value').then((snapshot) => {
            if (!snapshot.exists()) {
                return null;
            }
            return snapshot.val();
        });
    }

    changeRef(newRef) {
        this.ref = newRef; // so future saves will go off of
    }

    retrieveValueFromRef() {
        return this.ref.once('value');
    }

    setValueOnRef(value = null) {
        return this.ref.set(value);
    }

    updateValueOnRef(value = null) {
        return this.ref.update(value);
    }

    pushRefToListToGetKeyAndSetToNewRef() {
        const tempRef = this.ref.push();
        this.ref = tempRef;
    }
}

module.exports = Database;
