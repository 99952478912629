<template>
    <b-container>
        <p v-if="isLoading">
            Loading...
        </p>
        <router-view v-else />
    </b-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import { auth } from './firebase';

    export default {
        data() {
            return {
                isLoading: true,
            };
        },

        computed: {
            ...mapGetters('user', {
                user: 'getUser',
            }),
        },

        beforeCreate() {
            auth.onAuthStateChanged((user) => {
                if (user) {
                    this.handleUserIsLoggedIn(user);
                } else {
                    this.handlerUserIsNotLoggedIn();
                }
            });
        },

        methods: {
            ...mapActions('user', [
                'bindLocalUserToUserUID',
                'updateProjectBindingsToKey',
            ]),
            handleUserIsLoggedIn(user) {
                this.$store.commit('user/setLocalAccount', user);
                this.bindLocalUserToUserUID(user.uid).then(() => {
                    const projectKey = this.getFirstProjectKeyToBindTo();
                    if (projectKey) {
                        this.updateProjectBindingsToKey(projectKey).then(() => {
                            this.isLoading = false;
                        });
                    } else {
                        this.isLoading = false;
                    }

                    // if the user is signed in and they are on
                    // the login page, redirect them to user home.
                    if (this.$route.name === 'login') {
                        this.$router.push({ name: 'home' });
                    }
                });
            },
            handlerUserIsNotLoggedIn() {
                this.isLoading = false;

                // redirect to the login page if we the current
                // page is not already the login page.
                if (this.$route.name !== 'login') {
                    this.$router.push({ name: 'login' });
                }
            },
            getFirstProjectKeyToBindTo() {
                if (!this.user?.projects) {
                    return null;
                }
                const keys = Object.keys(this.user.projects);
                if (keys.length === 0) {
                    return null;
                }
                return keys[0];
            },
        },
    };
</script>
