import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

import ensureSystemDeviceTypesExist from './utils/ensureSystemDeviceTypes';

const firebaseConfig = {
    apiKey:            'AIzaSyDatgXau2RHjF2ZwfO1ezbGd8ND-f-qvo0',
    authDomain:        'labs-604.firebaseapp.com',
    databaseURL:       'https://labs-604.firebaseio.com',
    projectId:         'labs-604',
    storageBucket:     'labs-604.appspot.com',
    messagingSenderId: '17572272975',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const auth = firebaseApp.auth();
const db = firebaseApp.database();

if (window.location.hostname === 'localhost') {
    // Point to the RTDB emulator running on localhost.

    // auth.useEmulator has a different signature than
    // other useEmulator calls. This one accepts a single
    // parameter 'url' which is the fully qualified scheme://host:port.
    auth.useEmulator('http://localhost:9099');
    db.useEmulator('localhost', 9000); // Use only when necessary.
    firebaseApp.functions().useEmulator('localhost', 5001);

    ensureSystemDeviceTypesExist();
}

const getCloudFunctionByName = (functionName, version = 1) => firebaseApp
    .functions()
    .httpsCallable(`v${version}-${functionName}`);

export {
    firebaseApp,
    auth,
    db,
    getCloudFunctionByName,
};
