import FrontendTriggerStage from './FrontendTriggerStage';
import Connection from '../Connection';

const _ = require('underscore');

class FrontendConnection extends Connection {
    /**
     * If it's been deleted, we don't want it to trickle out.
     */
    static isSkippableDisplayData(connectionData) {
        if (!connectionData || !_.isObject(connectionData) || connectionData.timeDeleted) {
            return true;
        }

        return false;
    }

    /**
     * Filter for display.
     */
    static filterForDisplay(connectionDetails) {
        if (Connection.isSkippableDisplayData(connectionDetails)) {
            return {};
        }

        const tempFilteredConnectionVars = {
            connectionKey:           connectionDetails.connectionKey,
            name:                    connectionDetails.connectionName,
            createdAtLocal:          connectionDetails.createdAtLocal,
            sequenceKey:             connectionDetails.sequenceKey,
            triggersSequenceItemKey: connectionDetails.triggersSequenceItemKey,
            createdInProjectID:      connectionDetails.createdInProjectID,
            updatedAt:               connectionDetails.updatedAt,
        };

        if (connectionDetails.disabledAt) {
            tempFilteredConnectionVars.disabledAt = connectionDetails.disabledAt;
        }

        return tempFilteredConnectionVars;
    }

    postProcessDefaultData() {
        this.frontendTriggerStage = new FrontendTriggerStage(this.triggerStageData);
    }

    get isDisabled() {
        if (!_.isUndefined(this.disabledAt)) {
            return true;
        }
        return false;
    }
}

export default FrontendConnection;
