<template>
    <b-modal
        ref="modal"
        title="Add Connection"
        size="lg"
        @ok="handleSaveNewConnection"
        @hidden="resetModal"
    >
        <b-form>
            <b-container>
                <b-row>
                    <b-form-group
                        id="input-group-connection-name"
                        label="Connection Name:"
                        label-for="connection-name-input"
                        :state="properyIsValid('name')"
                        :invalid-feedback="validation.name.errors[0]"
                    >
                        <b-form-input
                            id="connection-name-input"
                            v-model="currConnection.frontendTriggerStage.name"
                            placeholder="Connection Name"
                            type="text"
                            :state="properyIsValid('name')"
                        />
                    </b-form-group>
                </b-row>
                <b-row>
                    <b-form-group
                        id="input-group-connection-loop"
                        label="How many times do you want the sequence to run?"
                        label-for="connection-max-loop-count-input"
                    >
                        <b-form-select
                            id="connection-max-loop-count-input"
                            v-model="formValues.maxLoopCount"
                            placeholder="1"
                            :options="maxLoopCountOptions"
                        />
                    </b-form-group>
                </b-row>
                <b-row>
                    <trigger-stage
                        ref="trigger-stage-modal"
                        :trigger-stage-model="currConnection.frontendTriggerStage"
                    />
                </b-row>
                <b-row>
                    <sequence ref="sequence-view-modal" />
                </b-row>
            </b-container>
        </b-form>
    </b-modal>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import { getCloudFunctionByName } from '../../../firebase';
    import FrontendConnection from '../../../../shared/models/frontend/FrontendConnection';
    import TriggerStage from './addConnection/TriggerStage.vue';
    import Sequence from './addConnection/Sequence.vue';

    const _ = require('underscore');

    export default {
        components: {
            TriggerStage,
            Sequence,
        },
        data() {
            return {
                formValues: {
                    // Used for setting default dropdown value on Trigger stage devices
                    currentlySelectedDeviceDropdownValue: 'default',
                },
                maxLoopCountOptions: [
                    { value: 0, text: '1' },
                    { value: 1, text: '2' },
                    { value: 2, text: '3' },
                    { value: 3, text: '4' },
                    { value: 4, text: '5' },
                    { value: 5, text: '6' },
                    { value: 6, text: '7' },
                    { value: 7, text: '8' },
                    { value: 8, text: '9' },
                    { value: 9, text: '10' },
                ],
                trueOrFalseOptions: [
                    { value: false, text: 'False' },
                    { value: true, text: 'True' },
                ],
                currConnection: new FrontendConnection({}),
            };
        },

        computed: {
            ...mapGetters('user', {
                currentProjectKey:                'getCurrentProjectKey',
                someCurrentFrontendDevicesObject: 'getCurrentProjectFrontendDevicesObject',
            }),

            validation() {
                return this.validateConnectionFromFormValues();
            },

            modal() {
                return this.$refs.modal;
            },
        },

        created() {
            this.resetModal();
            this.triggerDeviceOptions = this.generateTriggerDeviceDropdownOptions();
        },

        methods: {
            ...mapActions('user', [
                'getProjectsCanuseConnectionRef',
            ]),
            show() {
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },

            properyIsValid(key) {
                if (_.isNull(this.formValues[key])) {
                    return null;
                }
                return this.validation[key].isValid;
            },

            generateTriggerDeviceDropdownOptions() {
                const currTriggerStage = this.currConnection.frontendTriggerStage;
                return [
                    { value: 'default', text: 'Select a device from the dropdown...' },
                    ...currTriggerStage.availableDevicesForSelectionDropdown,
                ];
            },

            // Combination of all validations. Will need to be redone at some point to mirrow
            // how we did validations with Shiv.
            validateConnectionFromFormValues() {
                return this.currConnection.validate();
            },

            handleSaveNewConnection(evt) {
                evt.preventDefault();

                this.okWasClicked = true;
                const validations = this.validateConnectionFromFormValues();

                const { isValid } = validations;
                if (isValid) {
                    const localTimestamp = new Date().getTime();
                    const formattedConnectionDetails = FrontendConnection.generateJSONBlobForDB(this.formValues);
                    const projectsCanUseConnectionRef = this.getProjectsCanuseConnectionRef(this.currentProjectKey);

                    getCloudFunctionByName('addConnection')({
                        newConnectionDetails: formattedConnectionDetails,
                        currentProjectID:     this.currentProjectKey,
                        localTimestamp,
                    }).then(() => {
                        // This should hypothetically work. -JS

                        // Keeps it open until the localtimestamp filters its way through the
                        projectsCanUseConnectionRef.then((newProjectsCanUseRef) => {
                            newProjectsCanUseRef.on('value', (projectsCanUseConnectionSnapshot) => {
                                projectsCanUseConnectionSnapshot.forEach((tempProjectConnection) => {
                                    const tempConnectionLocalEditedTimestamp = tempProjectConnection.updatedAtLocal;
                                    if (localTimestamp === tempConnectionLocalEditedTimestamp) {
                                        newProjectsCanUseRef.off();
                                        this.hide();
                                    }
                                });
                            });
                        });
                    });
                }
            },

            handleSelectedDevice(currentlySelectedDeviceName) {
                // Undefined is passed onCreate (onMount?).
                const currTriggerStage = this.currConnection.frontendTriggerStage;
                const getFrontendDeviceorNull = currTriggerStage.getFrontendDeviceOrNull(currentlySelectedDeviceName);

                if (!_.isNull(getFrontendDeviceorNull)) {
                    currTriggerStage.markFrontendDeviceAsSelected(getFrontendDeviceorNull.deviceKey);
                }
                // Always reset to default.
                this.triggerDeviceDropdownPlaceholder = 'default';
            },

            removeSelectedDevice(currentlySelectedTriggerDevice) {
                this.currConnection.frontendTriggerStage.removeSelectedDeviceAndUpdateAvailableDevices(currentlySelectedTriggerDevice);
            },

            formatDeviceName(deviceName, deviceType) {
                return `${deviceName} (${deviceType})`;
            },

            resetModal() {
                this.currConnection = new FrontendConnection({
                    triggerStageData: {
                        frontendDevicesByDeviceKey: this.someCurrentFrontendDevicesObject,
                    },
                    sequenceData: {
                        // TODO
                    },
                });

                this.formValues = {
                    name:         this.currConnection.name,
                    maxLoopCount: 0,
                };
                this.okWasClicked = false;
            },
        },

    };
</script>
