<template>
    <div>
        <div class="device-table-container">
            <b-table
                responsive
                :fields="fields"
                :items="getConnectionMonitorData()"
                show-empty
                :empty-text="noConnectionMonitorsText"
            >
                <template #cell(currStageStatus)="data">
                    <div v-if="data.item.isCurrentlyActive">
                        <active-stage-connection-monitor-component
                            :frontend-connection-monitor="data.item"
                        />
                    </div>
                    <div v-else-if="data.item.devicesWereRerouted">
                        <rerouted-stage-connection-monitor-component
                            :frontend-connection-monitor="data.item"
                        />
                    </div>
                    <div v-else>
                        <successful-stage-connection-monitor-component />
                    </div>
                </template>
                <template #cell(actions)="data">
                    <b-button
                        v-if="data.item.finishedSuccessfully"
                        @click="stahpConnection(data.item.connectionMonitorKey, data.item.connectionKey)"
                    >
                        Delete
                    </b-button>
                    <b-button
                        v-else
                        @click="stahpConnection(data.item.connectionMonitorKey, data.item.connectionKey)"
                    >
                        Stahp
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ActiveStageConnectionMonitorComponent from './ActiveStageConnectionMonitorComponent.vue';
    import SuccessfulStageConnectionMonitorComponent from './SuccessfulStageConnectionMonitorComponent.vue';
    import { getCloudFunctionByName } from '../firebase';
    import ReroutedStageConnectionMonitorComponent from './ReroutedStageConnectionMonitorComponent.vue';

    export default {
        components: {
            SuccessfulStageConnectionMonitorComponent,
            ActiveStageConnectionMonitorComponent,
            ReroutedStageConnectionMonitorComponent,
        },
        data() {
            return {
                fields: [
                    // A virtual column that doesn't exist in items
                    {
                        key:      'createdAtTimestampLabel',
                        label:    'Created At',
                        sortable: true,
                    },
                    {
                        key:      'sequenceName',
                        label:    'Sequence Name',
                        sortable: true,
                    },
                    {
                        key:      'statusLabel',
                        label:    'Status',
                        sortable: true,
                    },
                    {
                        key:      'currStageStatus',
                        label:    'Current Stage',
                        sortable: true,
                    },
                    {
                        key:   'actions',
                        label: 'Actions',
                    },
                ],
            };
        },

        computed: {
            ...mapGetters('user', {
                currentProjectKey:                           'getCurrentProjectKey',
                someCurrentFrontendConnectionMonitorsObject: 'getCurrentProjectFrontendConnectionMonitorsObject',
            }),
            noConnectionMonitorsText() {
                return 'There are no Connection Monitors';
            },
        },

        methods: {
            getConnectionMonitorData() {
                return Object.values(this.someCurrentFrontendConnectionMonitorsObject);
            },
            stahpConnection(connectionMonitorKey, connectionKey) {
                const stopConnectionMonitor = getCloudFunctionByName('stopConnectionMonitor');
                const data = {
                    connectionMonitorKey,
                    connectionKey, // We need to make sure that the current project has access to the connection.
                    currProjectID: this.currentProjectKey,
                };

                stopConnectionMonitor(data).then((result) => {
                    // eslint-disable-next-line no-console
                    console.log(result);
                });
            },
        },
    };
</script>
