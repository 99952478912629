import FrontendConnection from '@shared/models/frontend/FrontendConnection';
import mutations from './mutations';
import actions from './actions';

// Order of operation here matters for circular dependencies.
import FrontendConnectionMonitor from '../../../shared/models/frontend/FrontendConnectionMonitor';
import FrontendDevice from '../../../shared/models/frontend/FrontendDevice';

export default {
    state: () => (
        {
            account:                          {},
            user:                             {},
            projects:                         {},
            currentProject:                   {},
            currentProjectDevices:            [],
            currentProjectConnectionMonitors: [],
            currentProjectConnections:        [],
        }
    ),
    getters: {
        getAccount:           (state) => state.account,
        getUser:              (state) => state.user,
        getUserId:            (state) => state.account.uid,
        getCurrentProject:    (state) => state.currentProject,
        getCurrentProjectKey: (state) => {
            if (state.currentProject?.projectKey) {
                return state.currentProject.projectKey;
            }
            return '';
        },
        getCurrentProjectFrontendDevicesObject: (state) => {
            const frontendDevicesObject = {};

            state.currentProjectDevices.forEach((item) => {
                frontendDevicesObject[item.deviceKey] = new FrontendDevice(item);
            });
            return frontendDevicesObject;
        },
        getCurrentProjectFrontendConnectionMonitorsObject: (state) => {
            const frontendConnectionMonitorsObject = {};

            state.currentProjectConnectionMonitors.forEach((item) => {
                frontendConnectionMonitorsObject[item.connectionMonitorKey] = new FrontendConnectionMonitor(item);
            });
            return frontendConnectionMonitorsObject;
        },
        getCurrentProjectFrontendConnectionsObject: (state) => {
            const frontendConnectionsObject = {};

            state.currentProjectConnections.forEach((item) => {
                frontendConnectionsObject[item.connectionKey] = new FrontendConnection(item);
            });
            return frontendConnectionsObject;
        },
    },
    mutations,
    actions,
    namespaced: true,
};
