// Node Module Imports
import Vue from 'vue';
import VueRouter from 'vue-router';
import underscore from 'vue-underscore';
import { BootstrapVue } from 'bootstrap-vue';
import { rtdbPlugin } from 'vuefire';

// import bootstrap css
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Local Imports
import store from './store/store';
import routes from './routes/routes';
import App from './App.vue';

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(rtdbPlugin);
Vue.use(underscore);

Vue.config.productionTip = false;

const router = new VueRouter({ mode: 'history', routes });

new Vue(
    Vue.util.extend({ router, store }, App),
).$mount('#app');
