<template>
    <div>
        <div class="stage-label">
            {{ frontendConnectionMonitor.currentStageAndSequenceLabel }}
        </div>
        <b-table
            responsive
            :fields="fields"
            :items="getStageDevices()"
            :tbody-tr-class="getBackgroundClass"
        />
    </div>
</template>

<script>

    export default {
        props: {
            frontendConnectionMonitor: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        data() {
            return {
                fields: [
                    {
                        key:      'deviceName',
                        label:    'Name',
                        sortable: true,
                    },
                    {
                        key:      'formattedDeviceKey',
                        label:    'Key',
                        sortable: true,
                    },
                    {
                        key:      'deviceVal',
                        label:    'On Device',
                        sortable: true,
                    },
                    {
                        key:      'idealVal',
                        label:    'On Cloud',
                        sortable: true,
                    },
                    {
                        key:      'formattedIdealTransitionSpeed',
                        label:    'Transition',
                        sortable: true,
                    },
                ],
            };
        },
        methods: {
            getStageDevices() {
                return this.frontendConnectionMonitor.frontendDevicesForCurrStage;
            },
            getBackgroundClass(item, type) {
                if (!item || type !== 'row') {
                    return '';
                }
                if (item.deviceOrIdealValUndefined) {
                    return 'table-warning';
                }
                if (item.deviceValMatchesIdealVal
                    && item.deviceValUpdatedAt > this.frontendConnectionMonitor.lastSequenceItemStageNumberUpdateTime) {
                    return 'table-success';
                }
                if (item.deviceValMatchesIdealVal) {
                    return 'dark-green-background';
                }
                return '';
            },
        },
    };
</script>

<style>
    .dark-green-background {
        background-color: #00994C;
    }
</style>
