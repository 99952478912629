<template>
    <div>
        <b-row>
            <b-col cols="9">
                <h1>Welcome {{ account.displayName }}</h1>
            </b-col>
            <b-col cols="3">
                <b-button
                    variant="primary"
                    @click="goToUserSettings"
                >
                    User Settings
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div>
                    <h5>Project {{ currentProject.name }} ({{ currentProject.projectKey }})</h5>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-button @click="openDeviceModal">
                    Add Device
                </b-button>
                <b-button @click="openConnectionModal">
                    Add Connection
                </b-button>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <div class="h4">
                    All Devices
                </div>
                <device-table-component />
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <div class="h4">
                    Live Connections (Connection Monitors)
                </div>
                <connection-monitor-historical-view-component />
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <div class="h4">
                    All Connections
                </div>
                <all-connections-historical-view-component />
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <create-device-modal ref="create-device-modal" />
                <create-connection-modal ref="create-connection-modal" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import CreateDeviceModal from './modals/CreateDeviceModal.vue';
    import CreateConnectionModal from './modals/CreateConnectionModal.vue';
    import DeviceTableComponent from '../DeviceTableComponent.vue';
    import ConnectionMonitorHistoricalViewComponent from '../ConnectionMonitorHistoricalViewComponent.vue';
    import AllConnectionsHistoricalViewComponent from '../AllConnectionsHistoricalViewComponent.vue';

    export default {
        components: {
            CreateDeviceModal,
            CreateConnectionModal,
            DeviceTableComponent,
            ConnectionMonitorHistoricalViewComponent,
            AllConnectionsHistoricalViewComponent,
        },

        computed: {
            ...mapGetters('user', {
                userId:         'getUserId',
                currentProject: 'getCurrentProject',
                account:        'getAccount',
            }),
            projects() {
                return this.$store.state.user.projects || [];
            },
            user() {
                return this.$store.state.user.userData || {};
            },
        },

        methods: {
            ...mapActions('user', [
                'getConnectionMonitors',
            ]),
            goToUserSettings() {
                this.$router.push({ name: 'user' });
            },
            openDeviceModal() {
                this.$refs['create-device-modal'].show();
            },
            openConnectionModal() {
                this.$refs['create-connection-modal'].show();
            },
        },
    };
</script>
<style>
    .container {
        max-width: 2000px !important;
    }
</style>
