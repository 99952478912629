const _ = require('underscore');

const FrontendDevice = require('./FrontendDevice');
const ConnectionMonitor = require('../ConnectionMonitor');

class FrontendConnectionMonitor extends ConnectionMonitor {
    // This is going to be needed.
    // eslint-disable-next-line no-useless-constructor
    constructor(frontendConnectionMonitorData) {
        super(frontendConnectionMonitorData);
    }

    get currentStageAndSequenceLabel() {
        return `Stage ${this.currentStageNumber} | ${Object.values(this.sequenceItems)[0].sequenceItemName}`;
    }

    get createdAtTimestampLabel() {
        return this.formatTimestampFromPropertyKey('createdAt');
    }

    get statusLabel() {
        if (this.currentSequenceItemStageNumber == 'finished'
        && this.finishedSuccessfully == true) {
            return 'Finished Successfully!';
        } if (this.currentSequenceItemStageNumber == 'finished'
        && this.finishedSuccessfully == false) {
            return 'Finished (Devices rerouted)';
        }
        return 'Ongoing';
    }

    get currStageStatus() {
        if (this.currentSequenceItemStageNumber == 'finished'
        && this.finishedSuccessfully == true) {
            return 'successfulStage';
        } if (this.currentSequenceItemStageNumber == 'finished'
        && this.finishedSuccessfully == false) {
            return 'reroutedStage';
        }
        return 'activeStage';
    }

    get devicesDataForCurrStage() {
        let currStageData = this.currentStageData;
        if (!currStageData) {
            currStageData = {};
        }

        // It felt right here.....not to fail gracefully. Like if this is borked, in this case I think it should be obvs.
        // We already failed gracefully a bit in the getters to arrive
        return currStageData.devicesData;
    }

    get frontendDevicesForCurrStage() {
        const stageDevices = [];
        const stageDevicesData = this.devicesDataForCurrStage;
        if (!_.isObject(stageDevicesData) || _.isEmpty(stageDevicesData)) { // stahp now
            return stageDevices;
        }

        _.mapObject(stageDevicesData, (reroutedDeviceData, deviceKey) => {
            stageDevices.push(new FrontendDevice({
                ...reroutedDeviceData,
                deviceKey,
            }));
        });
        return stageDevices;
    }
}

module.exports = FrontendConnectionMonitor;
