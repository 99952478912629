const Stage = require('./Stage');

class TriggerStage extends Stage {
    static get refPath() {
        return '/stages_triggers/';
    }

    static get validationRules() {
        // Here because we know that a storm's a brewin
        return {
            ...Stage.validationRules,
            // TODO validate allowWorkflowToTriggerStart
        };
    }

    static get defaultVals() {
        return {
            allowWorkflowToTriggerStart: false,
        };
    }

    fillWithTriggerStageDefaults() {
        this.setPropertiesForGivenJSONBlob(TriggerStage.defaultVals);
    }

    preProcessDefaultData(defaultsData) {
        this.fillWithDefaultStageData(defaultsData);
        this.fillWithTriggerStageDefaults(defaultsData);
    }
}

module.exports = TriggerStage;
