const _ = require('underscore');

const Database = require('../../../functions/db/Database');
const dbFunctions = require('../../../functions/db/functions.js');

const Device = require('../Device');
const EPOGlobalUtils = require('../../../functions/604GlobalUtils');

class BackendDevice extends Device {
    get currOrPreviousDeviceKey() {
        return (
            this.deviceKey
            || (this.dbNodeDeleted && this.previousModel.deviceKey)
        );
    }

    get shouldHaveStreamingEndpointData() {
        if (this.isDeleted) {
            return false;
        }

        if (!this.deviceStreamingKeyOnline) {
            return false;
        }

        if (!this.deviceStreamingKey) {
            return false;
        }

        if (!BackendDevice.isValidDeviceFirmwareVersionString(this.deviceStreamFirmware)) {
            return false;
        }

        return true;
    }

    get shouldUpdateStreamingEndpoint() {
        return !_.isEqual(
            this.getStreamingDataForDisplay(),
            this.getPreviousStreamingDataForDisplay(),
        );
    }

    get shouldUpdateListeningStages() {
        return this.displayDataHasChanged;
    }

    get shouldUpdateListeningConnectionMonitors() {
        return this.hasListeningConnectionMonitor && this.displayDataHasChanged;
    }

    get shouldUpdatePreviouslyListeningConnectionMonitors() {
        return this.hasListeningConnectionMonitor
            && this.idealConnectionMonitorDataHasChanged
            && this.previouslyHadListeningConnectionMonitor;
    }

    get hasListeningConnectionMonitor() {
        return dbFunctions.isValidIdealCMData(
            this.idealConnectionMonitorKey,
            this.idealSequenceItemKey,
            this.idealStageNumber,
        );
    }

    get previouslyHadListeningConnectionMonitor() {
        if (this.isNew) {
            return false;
        }

        return dbFunctions.isValidIdealCMData(
            this.previousModel.idealConnectionMonitorKey,
            this.previousModel.idealSequenceItemKey,
            this.previousModel.idealStageNumber,
        );
    }

    get idealConnectionMonitorDataHasChanged() {
        if (this.isDeleted && this.wasAlreadyDeleted) {
            return false;
        }

        let previousBackendDevice = {};
        if (!this.isNew) {
            previousBackendDevice = this.previousModel;
        }

        if (this.idealConnectionMonitorKey !== previousBackendDevice.idealConnectionMonitorKey) {
            return true;
        }

        if (this.idealSequenceItemKey !== previousBackendDevice.idealSequenceItemKey) {
            return true;
        }

        if (this.idealStageNumber !== previousBackendDevice.idealStageNumber) {
            return true;
        }

        return false;
    }

    static get refPath() {
        return 'devices';
    }

    static get refPathForStreamingDevices() {
        return 'streaming_device';
    }

    static get getAllowedDeviceFirmwareVersions() {
        // https://604labs.atlassian.net/wiki/spaces/TECH/pages/446824449/Firmware+Versions
        return [
            // '6L-1-0.2',
            // '6L-1-0.25',
            '6L-1-0.25',
            '6L-2-0.25',
        ]; // Always strings!
    }

    // The boards seem to fail below this, but it hasn't been stress tested too hard. -JS
    static get getLowestAllowedTransitionSpeed() {
        return 50;
    }

    static hasListeningConnectionMonitor(idealConnectionMonitorKey, idealSequenceItemKey, idealStageNumber) {
        if (!_.isString(idealConnectionMonitorKey)) {
            return false;
        } if (!_.isString(idealSequenceItemKey)) {
            return false;
        } if (parseInt(idealStageNumber, 10) != idealStageNumber) {
            return false;
        }

        return true;
    }

    static refPathForKey(key) {
        return dbFunctions.refPathForTypeAndKey(BackendDevice.refPath, key);
    }

    static refPathForStreamingDeviceByKey(key) {
        return `${BackendDevice.refPathForStreamingDevices}/${key}`;
    }

    static saveNewUpdatedAt(key) {
        return Database.saveNewUpdatedAtByModelAndKey(BackendDevice.refPath, key);
    }

    static canDeviceBeUsedByProject(deviceKey, projectKey) {
        return Database.getAllDeviceDataByDeviceKey(deviceKey)
            .then((existingDeviceData) => {
                if (!existingDeviceData) {
                    return false;
                }
                const tempDeviceCanBeUsedByProjectValidationData = Device.deviceCanBeUsedByProject(
                    deviceKey,
                    projectKey,
                    existingDeviceData.allowedProjects,
                );
                return (tempDeviceCanBeUsedByProjectValidationData === true);
            });
    }

    static handleDeviceOnlineCheckin(tempDeviceKey, tempCheckinType, tempFirmwareVersion) {
        return Database.updateDeviceData(BackendDevice.refPathForKey(tempDeviceKey), {
            deviceStreamingKeyOnline: true,
            deviceStreamFirmware:     tempFirmwareVersion,
            lastCheckinTime:          Database.serverTimestamp,
            lastCheckinType:          tempCheckinType,
        });
    }

    static handleDeviceIdealValCheckin(deviceKey, newIdealVal, checkinType) {
        if (!BackendDevice.deviceCheckinTypeIsAllowed(checkinType)) {
            return EPOGlobalUtils.generateNewBasicError(
                'deviceCheckInIdealValTypeError',
                'Illegal checkin type for idealval checkin supplied.',
            );
        }

        return Database.verifyDeviceByDeviceKeyAndGetDataIfExists(deviceKey)
            .then((deviceToCheckInData) => {
                if (!deviceToCheckInData) {
                    return EPOGlobalUtils.generateNewBasicError(
                        'deviceCheckInIdealValError',
                        'Device could not be found to check in its ideal val.',
                    );
                }
                // Here to validate that the new value is allowed.
                const deviceToCheckInModel = new BackendDevice(deviceToCheckInData[deviceKey]);

                // Set to new val and if it's valid, keep on truckin.
                deviceToCheckInModel.deviceVal = parseInt(newIdealVal, 10);
                const deviceToCheckIValidationData = deviceToCheckInModel.validate();

                if (!deviceToCheckIValidationData.isValid) {
                    return deviceToCheckIValidationData;
                }

                return Database.updateDeviceIdealValStats(
                    BackendDevice.refPathForKey(deviceKey),
                    newIdealVal,
                    checkinType,
                    BackendDevice.getLowestAllowedTransitionSpeed,
                );
            });
    }

    static handleDeviceRestartCheckin(deviceStreamingKey, deviceKey, checkinType, firmwareVersion) {
        return Database.handleDeviceRestartCheckin(
            BackendDevice.refPathForKey(deviceKey),
            deviceStreamingKey,
            checkinType,
            firmwareVersion,
        );
    }

    static handleDeviceValCheckin(deviceKey, newDeviceVal, newCheckinType, updateIdealVals) {
        if (BackendDevice.getAllowedDeviceCheckinTypes().indexOf(newCheckinType) === -1) {
            return EPOGlobalUtils.generateNewBasicError(
                'deviceCheckinTypeError',
                'Illegal checkin type was used.',
            );
        }

        return Database.verifyDeviceByDeviceKeyAndGetDataIfExists(deviceKey).then((deviceToCheckInData) => {
            if (!deviceToCheckInData) {
                return EPOGlobalUtils.generateNewBasicError(
                    'deviceCheckInError',
                    'Device could not be found to check in.',
                );
            }
            // Here to validate that the new value is allowed.

            const deviceToCheckInModel = new BackendDevice(deviceToCheckInData[deviceKey]);
            deviceToCheckInModel.deviceVal = parseInt(newDeviceVal, 10);

            const deviceToCheckIValidationData = deviceToCheckInModel.validate();
            if (!deviceToCheckIValidationData.isValid) {
                return deviceToCheckIValidationData;
            }

            return Database.updateDeviceValStats(
                BackendDevice.refPathForKey(deviceKey),
                newDeviceVal,
                newCheckinType,
                updateIdealVals,
                BackendDevice.getLowestAllowedTransitionSpeed,
            );
        });
    }

    static deviceCheckinTypeIsAllowed(currCheckinType) {
        return BackendDevice.getAllowedDeviceCheckinTypes().indexOf(currCheckinType) !== -1;
    }

    static getAllowedDeviceCheckinTypes() {
        // definitions here: https://604labs.atlassian.net/wiki/spaces/TECH/pages/455114753/Checkin+Action+Types
        return [
            'onlineAction',
            'initializeAction',
            'deviceCreation',
            'sequenceAction',
            'systemAction',
            'intentionalAction',
            'restartAction',
        ];
    }

    static generateIdealValUpdatesJSON(idealUpdatesJSON, checkinType = 'sequenceAction') {
        // we need stageStartTime to match idealVal times in the connectionMonitor,
        // not be totally accurate ;)
        const deviceIdealData = {
            idealVal:                  idealUpdatesJSON.currIdealVal,
            idealStageNumber:          idealUpdatesJSON.newStageNumber,
            idealTransitionSpeed:      idealUpdatesJSON.currReceiverIdealTransitionSpeed,
            idealTransitionType:       idealUpdatesJSON.currReceiverIdealTransitionType,
            idealConnectionMonitorKey: idealUpdatesJSON.connectionMonitorKey,
            idealSequenceItemKey:      idealUpdatesJSON.sequenceItemKey,
            idealStageStartTime:       idealUpdatesJSON.stageStartTime,
            idealValUpdatedAt:         idealUpdatesJSON.stageStartTime,
            lastIdealCheckinType:      checkinType,
        };
        return deviceIdealData;
    }

    static isValidDeviceFirmwareVersionString(tempDeviceFirmware) {
        if (!_.isString(tempDeviceFirmware)) {
            return false;
        } if (!BackendDevice.isAllowedFirmwareString(tempDeviceFirmware)) {
            return false;
        }

        return true;
    }

    static isAllowedFirmwareString(versionString) {
        return BackendDevice.getAllowedDeviceFirmwareVersions.indexOf(versionString) !== -1;
    }

    static getDataForStreamingEndpoint(backendDeviceModel) {
        if (!backendDeviceModel.shouldHaveStreamingEndpointData) {
            return {};
        }
        return BackendDevice.filterDataForStreamingEndpoint(backendDeviceModel);
    }

    static getDataForDisplay(backendDeviceModel) {
        if (backendDeviceModel.isDeleted) {
            return {};
        }

        const tempFilteredDeviceVars = {
            createdAt:            backendDeviceModel.createdAt,
            deviceKey:            backendDeviceModel.deviceKey,
            name:                 backendDeviceModel.name,
            deviceUseBoolean:     backendDeviceModel.isBooleanDevice,
            deviceUsePercent:     backendDeviceModel.isPercentDevice,
            deviceTypeName:       backendDeviceModel.deviceTypeName,
            deviceTypeID:         backendDeviceModel.deviceTypeID,
            deviceVal:            backendDeviceModel.deviceVal,
            deviceValUpdatedAt:   backendDeviceModel.deviceValUpdatedAt,
            lastDeviceValType:    backendDeviceModel.lastDeviceValType,
            idealVal:             backendDeviceModel.idealVal,
            idealValUpdatedAt:    backendDeviceModel.idealValUpdatedAt,
            lastIdealCheckinType: backendDeviceModel.lastIdealCheckinType,
            idealTransitionSpeed: backendDeviceModel.idealTransitionSpeed, // some of these should be receiver-device only. -JS
            idealTransitionType:  backendDeviceModel.idealTransitionType,
            lastCheckinTime:      backendDeviceModel.lastCheckinTime,
            lastCheckinType:      backendDeviceModel.lastCheckinType,
            createdAtLocal:       backendDeviceModel.createdAtLocal,
            inputLowLimit:        backendDeviceModel.inputLowLimit,
            inputHighLimit:       backendDeviceModel.inputHighLimit,
            outputLowLimit:       backendDeviceModel.outputLowLimit,
            outputHighLimit:      backendDeviceModel.outputHighLimit,
            updatedAt:            backendDeviceModel.updatedAt,
        };

        if (backendDeviceModel.idealConnectionMonitorKey) {
            tempFilteredDeviceVars.idealConnectionMonitorKey = backendDeviceModel.idealConnectionMonitorKey;
            tempFilteredDeviceVars.idealSequenceItemKey = backendDeviceModel.idealSequenceItemKey;
            tempFilteredDeviceVars.idealStageNumber = backendDeviceModel.idealStageNumber;
        }

        // Only set if needed. Can be revoked too.
        if (backendDeviceModel.deviceStreamingKeyOnline) {
            tempFilteredDeviceVars.deviceStreamingKeyOnline = backendDeviceModel.deviceStreamingKeyOnline;
        }

        // Only set if needed. Can be revoked too.
        if (backendDeviceModel.deviceStreamFirmware) {
            tempFilteredDeviceVars.deviceStreamFirmware = backendDeviceModel.deviceStreamFirmware;
        }

        // Only set if needed. Can be revoked too.
        if (backendDeviceModel.deviceStreamingKey) {
            tempFilteredDeviceVars.deviceStreamingKey = backendDeviceModel.deviceStreamingKey;
        }

        // Just for receiverDevice validation/display
        if (backendDeviceModel.idealComparator) {
            tempFilteredDeviceVars.idealComparator = backendDeviceModel.idealComparator;
        }

        if (backendDeviceModel.deviceStreamFirmware) {
            tempFilteredDeviceVars.deviceStreamFirmware = backendDeviceModel.deviceStreamFirmware;
        }

        return tempFilteredDeviceVars;
    }

    static filterDataForStreamingEndpoint(deviceDetails) {
        const tempFirmwareID = deviceDetails.deviceStreamFirmware;
        if (BackendDevice.isUsingUptoDateReceiverFirmware(tempFirmwareID)) {
            return BackendDevice.getUpToDateReceiverFilteredData(deviceDetails);
        } if (BackendDevice.isUsingUptoDateTriggerFirmware(tempFirmwareID)) {
            return BackendDevice.getUpToDateTriggerFilteredData(deviceDetails);
        }

        return null;
    }

    static getUpToDateTriggerFilteredData(deviceDetails) {
        return BackendDevice.getBaseStreamingDataFromObject(deviceDetails, true);
    }

    static getUpToDateReceiverFilteredData(deviceDetails) {
        return {
            ...BackendDevice.getBaseStreamingDataFromObject(deviceDetails, false),
            deviceStreamingKey: deviceDetails.deviceStreamingKey,
        };
    }

    static getBaseStreamingDataFromObject(data, useInputData) {
        let inputOutputData;
        if (useInputData) {
            inputOutputData = BackendDevice.getInputLimitsFromObject(data);
        } else {
            inputOutputData = BackendDevice.getOutputLimitsFromObject(data);
        }

        return {
            ...BackendDevice.getIdealValDataBlobFromObject(data),
            ...inputOutputData,
            lastCheckinType: data.lastCheckinType,
        };
    }

    static getIdealValDataBlobFromObject(data) {
        const idealValData = {
            idealVal:             data.idealVal,
            idealValUpdatedAt:    data.idealValUpdatedAt,
            idealTransitionSpeed: data.idealTransitionSpeed, // some of these should be receiver-device only. -JS
            idealTransitionType:  data.idealTransitionType,
            lastIdealCheckinType: data.lastIdealCheckinType,
            // Not in triggers because those don't have initializeAction,
            // and their checkins were causing trouble with "lastCheckinType".
        };

        if (data.idealConnectionMonitorKey) {
            idealValData.idealConnectionMonitorKey = data.idealConnectionMonitorKey;
            idealValData.idealSequenceItemKey = data.idealSequenceItemKey;
            idealValData.idealStageNumber = data.idealStageNumber;
        }

        return idealValData;
    }

    static getInputLimitsFromObject(data) {
        const inputData = {
            inputLowLimit:  data.inputLowLimit,
            inputHighLimit: data.inputHighLimit,
        };

        return inputData;
    }

    static getOutputLimitsFromObject(data) {
        const outputData = {
            outputLowLimit:  data.outputLowLimit,
            outputHighLimit: data.outputHighLimit,
        };

        return outputData;
    }

    static setDeletedTimestamp(deviceKey, uid) {
        return Database.updateDeletedTimestamp(BackendDevice.refPathForKey(deviceKey), uid);
    }

    generateReroutedDeviceData() {
        return {
            rerouted:                       true,
            reroutedToConnectionMonitorKey: this.idealConnectionMonitorKey,
            reroutedToSequenceItemKey:      this.idealSequenceItemKey,
            reroutedToStage:                this.idealStageNumber,
        };
    }

    postProcessDefaultData() {
        if (this.hasAllowedBeforeData) {
            this.previousModel = new BackendDevice(this.beforeData);
        }
    }

    saveToDatabase(requestedProjectID) {
        const newKey = Database.getNewKeyForPath(BackendDevice.refPath);

        const deviceJSONToSave = {
            createdAt:            Database.serverTimestamp,
            createdAtLocal:       this.createdAtLocal,
            name:                 this.name,
            deviceTypeName:       this.deviceTypeName,
            deviceTypeID:         this.deviceTypeID,
            deviceValUpdatedAt:   Database.serverTimestamp,
            lastDeviceValType:    'deviceCreation',
            deviceKey:            newKey,
            deviceVal:            0,
            idealVal:             0,
            idealValUpdatedAt:    Database.serverTimestamp,
            lastIdealCheckinType: 'deviceCreation',
            lastCheckinTime:      Database.serverTimestamp,
            lastCheckinType:      'deviceCreation',
            updatedAt:            Database.serverTimestamp,
            allowedProjects:      {
                [requestedProjectID]: true,
            },
        };

        if (this.isPercentDevice) {
            deviceJSONToSave.deviceUsePercent = true;
        } else if (this.isBooleanDevice) {
            deviceJSONToSave.deviceUseBoolean = true;
        }

        return Database.saveNewDevice(BackendDevice.refPathForKey(newKey), deviceJSONToSave);
    }

    updateListeningStages() {
        return Database.updateListeningStages(
            this.deviceKey,
            this.getDataForDisplay(),
        );
    }

    updateListeningConnectionMonitors() {
        return Database.updateListeningConnectionMonitor(
            this.idealConnectionMonitorKey,
            this.idealSequenceItemKey,
            this.deviceKey,
            this.getDataForDisplay(),
            true,
        );
    }

    updatePreviousListeningConnectionMonitors() {
        // Passing in false because if the old CM is gone, the device has already moved on and
        // doesn't need to change. Never change, always be yourself, device.
        return Database.updateListeningConnectionMonitor(
            this.previousModel.idealConnectionMonitorKey,
            this.previousModel.idealSequenceItemKey,
            this.deviceKey,
            this.getDataForDisplay(),
            false,
        );
    }

    updateProjectsCanUseDevice() {
        return Database.updateProjectsCanUseDevice(
            this.allowedProjectsFromCurrentOrPrevious,
            this.deviceKey,
            this.getDataForDisplay(),
        );
    }

    updateOrRemoveDeviceStreamingEndpoint() {
        return Database.updateOrRemoveDeviceStreamingEndpoint(
            this.deviceStreamingKey,
            this.getStreamingDataForDisplay(),
        );
    }

    getDataForDisplay() {
        return BackendDevice.getDataForDisplay(this);
    }

    getStreamingDataForDisplay() {
        return BackendDevice.getDataForStreamingEndpoint(this);
    }

    getPreviousStreamingDataForDisplay() {
        let previousModel;
        if (this.hasAllowedBeforeData) {
            previousModel = this.previousModel;
        } else {
            previousModel = new BackendDevice(this.beforeData);
        }
        return BackendDevice.getDataForStreamingEndpoint(previousModel);
    }
}

module.exports = BackendDevice;
