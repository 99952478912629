<template>
    <b-modal
        ref="modal"
        title="Add Device"
        @ok="handleSaveNewDevice"
        @hidden="resetModal"
    >
        <b-form>
            <b-container>
                <b-row>
                    <b-form-group
                        id="input-group-device-name"
                        label="Device Name:"
                        label-for="device-name-input"
                        :state="properyIsValid('name')"
                        :invalid-feedback="validation.name.errors[0]"
                    >
                        <b-form-input
                            id="device-name-input"
                            v-model="formValues.name"
                            placeholder="Device Name"
                            type="text"
                            :state="properyIsValid('name')"
                        />
                    </b-form-group>
                </b-row>
                <b-row>
                    <b-form-group
                        id="input-group-device-type"
                        label="Device Type:"
                        label-for="device-type-input"
                        :state="properyIsValid('deviceTypeID')"
                        :invalid-feedback="validation.deviceTypeID.errors[0]"
                    >
                        <b-form-select
                            id="device-type-input"
                            v-model="formValues.deviceTypeID"
                            :options="possibleTypes"
                            :state="properyIsValid('deviceTypeID')"
                        />
                    </b-form-group>
                </b-row>
            </b-container>
        </b-form>
    </b-modal>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import { db, getCloudFunctionByName } from '@/firebase';
    import Device from '@models/Device';

    export default {

        data() {
            return {
                formValues: {
                    name:         null,
                    deviceTypeID: null,
                },
                rawPossibleTypes: [],
                okWasClicked:     false,
            };
        },

        computed: {
            ...mapGetters('user', {
                currentProjectKey: 'getCurrentProjectKey',
            }),
            possibleTypes() {
                const values = [{
                    disabled: true,
                    value:    null,
                    text:     'Select a type',
                }];
                const moreValues = this.rawPossibleTypes.map((rawType) => ({
                    text:       rawType.deviceTypeName,
                    value:      rawType.deviceTypeKey,
                    sort_order: rawType.sort_order,
                })).sort((element1, element2) => element1.sort_order > element2.sort_order);
                values.push(...moreValues);
                return values;
            },

            validation() {
                return this.validateDeviceFromFormValues();
            },

            modal() {
                return this.$refs.modal;
            },
        },

        firebase: {
            rawPossibleTypes: db.ref('system_device_types'),
        },

        created() {
            this.resetModal();
        },

        methods: {
            ...mapActions('user', [
                'getProjectsCanuseDeviceRef',
            ]),
            show() {
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },
            properyIsValid(key) {
                if (!this.okWasClicked) {
                    return null;
                }
                return this.validation[key].isValid;
            },
            validateDeviceFromFormValues() {
                return (new Device(this.formValues)).validate();
            },
            handleSaveNewDevice(evt) {
                evt.preventDefault();

                this.okWasClicked = true;
                const validations = this.validateDeviceFromFormValues();

                const { isValid } = validations;
                if (isValid) {
                    // This is a signature that we will listen for. When it comes back through this ref,
                    // we know that it is exposed to the user.
                    const localTimestamp = new Date().getTime();
                    const projectsCanuseDeviceRef = this.getProjectsCanuseDeviceRef(this.currentProjectKey);

                    getCloudFunctionByName('addDevice')({
                        newDeviceDetails:  this.formValues,
                        currentProjectKey: this.currentProjectKey,
                        localTimestamp,
                    }).then((results) => {
                        // eslint-disable-next-line no-console
                        console.log(results);

                        // Keeps it open until the localtimestamp filters its way through the
                        projectsCanuseDeviceRef.then((newRef) => {
                            newRef.on('value', (projectsCanuseDeviceSnapshot) => {
                                projectsCanuseDeviceSnapshot.forEach((tempProjectDevice) => {
                                    const availableDeviceJSON = tempProjectDevice.toJSON();

                                    if (localTimestamp === availableDeviceJSON.createdAtLocal) {
                                        newRef.off(); // Stop listening, plz.
                                        this.hide();
                                    }
                                });
                            });
                        });
                    });
                }
            },

            resetModal() {
                const newDevice = new Device();
                this.formValues = {
                    name:         newDevice.name,
                    deviceTypeID: null, // So it prefills to a stand-in default dropdown item.
                };
                this.okWasClicked = false;
            },
        },

    };
</script>
