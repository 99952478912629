/* eslint-disable */
(function () {
    if (typeof module !== 'undefined') {
        var _ = require('underscore');
    } else {
        var { _ } = window;
    }
    const EPOGlobalUtils = {
        // Blank errorData arrays turn into no error-state.
        generateNewBasicError(label, errorMessage, errorData, hideWarnings) {
            const hasError = (
                _.isUndefined(errorData) // If it's just an error message, it's still an error.
				|| (errorData.length > 0) // If you're passing in an array, it's gotta have stuff in it.
            );
            const tempErrorData = {
                hasError,
            };

            if (hasError) {
                tempErrorData[(label)] = true;
                tempErrorData.errorMessage = errorMessage;
                if (!_.isUndefined(errorData)) {
                    tempErrorData.errors = errorData;
                }

                // if (hideWarnings !== true) {
                // 	console.warn(tempErrorData);
                // }
            }
            return tempErrorData;
        },

        turnArrayOfObjectsToSingleObject(modelParts) {
            const tempAttributes = {};
            for (let i = 0; i < modelParts.length; i++) {
                _.extend(tempAttributes, modelParts[i]);
            }
            return tempAttributes;
        },

        getFilteredBooleanVal(tempVal) {
            if (tempVal != 0 && tempVal != 100) {
                return 100;
            } if (parseInt(tempVal) != tempVal) {
                return 100;
            }
            return parseInt(tempVal);
        },

        getFilteredPercentVal(tempVal) {
            if (parseInt(tempVal) <= 0) {
                return 0;
            } if (parseInt(tempVal) >= 100) {
                return 100;
            } if (parseInt(tempVal) != tempVal) {
                return 0;
            }
            return parseInt(tempVal);
        },

        getTriggerValFromTriggerValData(triggerValData) {
            // Cause we need to convert from a blob to a single value.
            // Since it only has one property in there, this is safe.
            // https://stackoverflow.com/questions/983267/how-to-access-the-first-property-of-a-javascript-object
            return triggerValData[Object.keys(triggerValData)[0]];
        },

        getFormattedDate(createdAtTimestamp) {
            const createdAtDate = new Date(createdAtTimestamp);
            let hr = createdAtDate.getHours();
            let min = createdAtDate.getMinutes();
            let sec = createdAtDate.getSeconds();

            if (min < 10) {
                min = `0${min}`;
            }
            if (sec < 10) {
                sec = `0${sec}`;
            }

            let ampm = 'am';
            if (hr > 12) {
                hr -= 12;
                ampm = 'pm';
            }
            let dd = createdAtDate.getDate();
            const mm = createdAtDate.getMonth() + 1; // January is 0!

            const yyyy = createdAtDate.getFullYear();
            if (dd < 10) {
                dd = `0${dd}`;
            }

            return `${hr}:${min}:${sec}${ampm} (${mm}/${dd}/${yyyy})`;
        },

        leftTruncatedString(fullString) {
            return `....${fullString.substr(fullString.length - 5)}`;
        },
    };

    if (typeof module !== 'undefined') {
        module.exports = EPOGlobalUtils;
    } else {
        define([], () => EPOGlobalUtils);
    }
}());
