<template>
    <b-form>
        <b-container
            class="border my-2 px-4"
        >
            <b-row>
                <b-form-group
                    id="input-group-trigger-stage-name"
                    label="Trigger Stage Name"
                    label-for="trigger-stage-name-input"
                >
                    <b-form-input
                        id="trigger-stage-name-input"
                        v-model="frontendTriggerStageModel.name"
                        placeholder="Enter name"
                        type="text"
                    />
                </b-form-group>
            </b-row>
            <b-row>
                <b-form-group
                    id="input-group-trigger-stage-workflow"
                    label="Allow other sequences to trigger this connection?"
                    label-for="trigger-stage-workflow"
                >
                    <b-form-select
                        id="trigger-stage-workflow"
                        v-model="triggerWorkflow"
                        :options="yesOrNoDropdownChoices"
                    />
                </b-form-group>
            </b-row>
            <b-row>
                <b-form-group
                    id="input-group-trigger-stage-devices"
                    label="Select the Trigger Devices"
                    label-for="trigger-stage-device-input"
                >
                    <!-- triggerDeviceDropdownPlaceholder is a way to auto set the value back to default -->
                    <b-form-select
                        id="trigger-stage-device-input"
                        v-model="triggerDeviceDropdownPlaceholder"
                        :options="triggerDeviceOptions"
                        :disabled="noDevicesInProject"
                        @change="handleSelectedDevice"
                    />
                </b-form-group>
                <b-alert
                    variant="danger"
                    :show="noDevicesInProject"
                >
                    Add devices to your project to create connections!
                </b-alert>
            </b-row>
            <b-row>
                <b-table
                    ref="selectedTriggerDeviceTable"
                    bordered
                    :items="frontendTriggerStageModel.selectedFrontendDeviceArray"
                    :fields="selectedTriggerDeviceFields"
                    show-empty
                >
                    <template #empty="">
                        <p> There are no devices currently added </p>
                    </template>
                    <template #cell(conditions)="data">
                        <b-form-group
                            id="input-group-connection-loop"
                            label="Comparison for trigger activation:"
                            label-for="selectedDeviceComparisonType"
                        >
                            <b-form-select
                                id="selectedDeviceComparisonType"
                                v-model="data.item.comparisonType"
                                :options="data.item.optionsForDeviceTriggerComparisonType"
                            />
                        </b-form-group>
                        <div v-if="FrontendTriggerStage.triggerStageComparisonAllowsInput(data.item.comparisonType)">
                            <div v-if="data.item.isPercentDevice">
                                <b-form-group
                                    label="Comparison value:"
                                    label-for="device-comparison-value-input"
                                    append="%"
                                >
                                    <b-form-input
                                        id="device-comparison-value-input"
                                        v-model="data.item.comparisonVal"
                                        type="number"
                                    />
                                </b-form-group>
                            </div>
                            <div v-else-if="data.item.isBooleanDevice">
                                <b-form-group
                                    label="Comparison Value:"
                                    label-for="device-comparison-boolean-value-input"
                                >
                                    <b-form-select
                                        id="device-comparison-boolean-value-input"
                                        v-model="data.item.comparisonVal"
                                        :options="trueOrFalseDropdownChoices"
                                    />
                                </b-form-group>
                            </div>
                        </div>
                    </template>
                    <template #cell(remove)="data">
                        <b-button
                            @click="removeSelectedDevice(data.item.deviceKey)"
                        >
                            Remove
                        </b-button>
                    </template>
                </b-table>
            </b-row>
        </b-container>
    </b-form>
</template>

<script>
    import FrontendDevice from '@shared/models/frontend/FrontendDevice';
    import { mapGetters, mapActions } from 'vuex';

    import FrontendTriggerStage from '../../../../../shared/models/frontend/FrontendTriggerStage';
    import { generateFieldsForBTable, getNewTimestamp } from '../../../../utils/frontendUtils';

    const _ = require('underscore');

    export default {

        props: {
            frontendTriggerStageModel: {
                type:    FrontendTriggerStage,
                default: new FrontendTriggerStage(),
            },
        },

        data() {
            return {
                formValues: {
                    // Used for setting default dropdown value on Trigger stage devices
                    currentlySelectedDeviceDropdownValue: 'default',
                },
                selectedTriggerDeviceFields: [
                    // https://stackoverflow.com/questions/51541671/make-bootstrap-vue-b-table-id-column-invisible
                    { key: 'deviceKey', thClass: 'd-none', tdClass: 'd-none' },
                    generateFieldsForBTable('name', 'Name'),
                    generateFieldsForBTable('deviceTypeName', 'deviceTypeName'),
                    generateFieldsForBTable('conditions', 'Conditions', false),
                    generateFieldsForBTable('remove', 'Remove', false),
                ],
                triggerDeviceDropdownPlaceholder: 'default',
                yesOrNoDropdownChoices:           [
                    { text: 'No', value: false },
                    { text: 'Yes', value: true },
                ],
                trueOrFalseDropdownChoices: [
                    { text: 'True', value: true },
                    { text: 'False', value: false },
                ],
                triggerWorkflow:              false,
                FrontendTriggerStage,
                lastDeviceSelectionTimestamp: '', // used to trigger updating the UI since we couldn't get the watchers to work
            };
        },

        computed: {
            ...mapGetters('user', {
                currentProjectKey:                'getCurrentProjectKey',
                someCurrentFrontendDevicesObject: 'getCurrentProjectFrontendDevicesObject',
            }),

            validation() {
                return this.validateConnectionFromFormValues();
            },

            modal() {
                return this.$refs.modal;
            },

            noDevicesInProject() {
                return this.frontendTriggerStageModel.frontendDevicesByDeviceKey.length === 0;
            },

            triggerDeviceOptions() {
                const values = [{ value: 'default', text: 'Select a device from the dropdown...' }];

                // Ideally we would be following this object on its own, but we can't get watchers to work right. -JS
                _.mapObject(this.frontendTriggerStageModel.frontendDevicesByDeviceKey, (tempFrontendDevice) => {
                    values.push({
                        text:      tempFrontendDevice.name,
                        value:     tempFrontendDevice.deviceKey,
                        disabled:  tempFrontendDevice.disabled,
                        timestamp: this.lastDeviceSelectionTimestamp, // this reference is what causes the UI update to trigger.
                    });
                });
                return values;
            },
        },

        created() {
            this.lastDeviceSelectionTimestamp = getNewTimestamp(); // Necessary for updating UI
            this.resetFormValues();

            // For testing purposes
            const dummyFrontendDevice = new FrontendDevice({
                name:             'dmub',
                deviceKey:        '-fakeDeviceKey',
                deviceUseBoolean: true,
            });
            const dummyPercentFrontendDevice = new FrontendDevice({
                name:             'dmub2',
                deviceKey:        '-fakeDeviceKey2',
                deviceUsePercent: true,
            });
            const currKey = dummyFrontendDevice.deviceKey;
            this.frontendTriggerStageModel.frontendDevicesByDeviceKey[currKey] = dummyFrontendDevice;
            const currKey2 = dummyPercentFrontendDevice.deviceKey;
            this.frontendTriggerStageModel.frontendDevicesByDeviceKey[currKey2] = dummyPercentFrontendDevice;
        },

        methods: {
            ...mapActions('user', [
                'getProjectsCanuseConnectionRef',
            ]),
            show() {
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },

            properyIsValid(key) {
                if (_.isNull(this.formValues[key])) {
                    return null;
                }
                return this.validation[key].isValid;
            },

            // Combination of all validations. Will need to be redone at some point to mirrow
            // how we did validations with Shiv.
            validateConnectionFromFormValues() {
                return this.frontendTriggerStageModel.validate();
            },

            handleSelectedDevice(currentlySelectedDeviceKey) {
                this.frontendTriggerStageModel.markFrontendDeviceAsSelected(currentlySelectedDeviceKey);
                // Always reset to default.
                this.resetSeletedDeviceDropdown();
                this.lastDeviceSelectionTimestamp = getNewTimestamp();
            },

            resetSeletedDeviceDropdown() {
                this.triggerDeviceDropdownPlaceholder = 'default';
            },

            removeSelectedDevice(currentlySelectedDeviceKey) {
                this.frontendTriggerStageModel.markFrontendDeviceAsNotSelected(currentlySelectedDeviceKey);
                this.lastDeviceSelectionTimestamp = getNewTimestamp();
            },

            formatDeviceName(deviceName, deviceType, isPercent) {
                const percentOrBoolean = isPercent ? 'Percent' : 'Boolean';
                return `${deviceName} (${deviceType} - ${percentOrBoolean})`;
            },

            resetFormValues() {
                this.formValues = {
                    name: this.frontendTriggerStageModel.name,
                };
            },
        },

    };
</script>
