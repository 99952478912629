<template>
    <div>
        <div class="device-table-container">
            <b-table
                ref="devicesTable"
                responsive
                :fields="fields"
                :items="getDevicesTableArray()"
                show-empty
                :empty-text="noDevicesText"
            >
                <template #cell(deviceKey)="data">
                    <blink-background-component
                        :label="data.item.formattedDeviceKey + ' (Copy ID)'"
                        :string-to-copy="data.item.deviceKey"
                    />
                    <hr>
                    <blink-background-component
                        v-if="data.item.deviceStreamingKey"
                        :label="data.item.formattedStreamingKey
                            + ' (Copy Streaming Key)'"
                        :string-to-copy="data.item.deviceStreamingKey"
                    />
                    <div v-else>
                        no streaming key
                    </div>
                </template>
                <template #cell(setDeviceVal)="data">
                    <device-val-shortcut-component :device-data="data" />
                </template>
                <template #cell(setIdealVal)="data">
                    <ideal-val-shortcut-component :device-data="data" />
                </template>
                <template #cell(actions)="data">
                    <!-- disabled and an ugly shade as a reminder that we need to get this working -->
                    <b-button
                        variant="info"
                        disabled
                        @click="restartDevice(data.item.deviceKey)"
                    >
                        Restart
                    </b-button>
                    <b-button @click="deleteDevice(data.item.deviceKey)">
                        Delete Device
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BlinkBackgroundComponent from './BlinkBackgroundComponent.vue';
    import { getCloudFunctionByName } from '../firebase';
    import DeviceValShortcutComponent from './DeviceValShortcutComponent.vue';
    import IdealValShortcutComponent from './IdealValShortcutComponent.vue';

    export default {
        components: {
            BlinkBackgroundComponent,
            DeviceValShortcutComponent,
            IdealValShortcutComponent,
        },
        data() {
            return {
                fields: [
                    // A virtual column that doesn't exist in items
                    {
                        key:      'deviceKey',
                        label:    'ID',
                        sortable: true,
                    },
                    {
                        key:      'name',
                        label:    'Name',
                        sortable: true,
                    },
                    {
                        key:      'deviceTypeName',
                        label:    'Type',
                        sortable: true,
                    },
                    {
                        key:      'deviceVal',
                        label:    'Val',
                        sortable: true,
                    },
                    {
                        key:      'deviceValUpdatedAtTimestampLabel',
                        label:    'ValUpdated',
                        sortable: true,
                    },
                    {
                        key:      'idealVal',
                        label:    'Ideal',
                        sortable: true,
                    },
                    {
                        key:      'idealValUpdatedAtTimestampLabel',
                        label:    'IdealUpdated',
                        sortable: true,
                    },
                    {
                        key:      'lastIdealCheckinType',
                        label:    'lastIdealCheckin',
                        sortable: true,
                    },
                    {
                        key:      'lastCheckinTimeLabel',
                        label:    'lastCheckinAt',
                        sortable: true,
                    },
                    {
                        key:      'lastCheckinType',
                        label:    'lastCheckinType',
                        sortable: true,
                    },
                    {
                        key:     'setDeviceVal',
                        label:   'Set device val',
                        thClass: 'setValInputWidth',
                        tdClass: 'setValInputWidth',
                        thStyle: { width: '325px' },
                    },
                    {
                        key:     'setIdealVal',
                        label:   'Set ideal val',
                        thClass: 'setValInputWidth',
                        tdClass: 'setValInputWidth',
                        thStyle: { width: '325px' },
                    },
                    {
                        key:   'actions',
                        label: 'Actions',
                    },
                ],
                shortcutButtonVals: [0, 25, 50, 75, 100],
                options:            [
                    { value: 0, text: 'Off' },
                    { value: 100, text: 'On' },
                ],
            };
        },
        computed: {
            ...mapGetters('user', {
                currentProjectKey:                'getCurrentProjectKey',
                someCurrentFrontendDevicesObject: 'getCurrentProjectFrontendDevicesObject',
            }),
            noDevicesText() {
                return 'There are no devices.';
            },
        },
        methods: {
            getDevicesTableArray() {
                return Object.values(this.someCurrentFrontendDevicesObject);
            },

            getDeviceByKeyFromLocalStore(deviceKey) {
                return this.someCurrentFrontendDevicesObject[deviceKey];
            },

            restartDevice(key) {
                const cloudFunctionName = 'restartDevice';
                const restartDeviceCloudFunction = getCloudFunctionByName(cloudFunctionName);

                restartDeviceCloudFunction({
                    deviceToBeRestartedKey: key,
                    currProjectID:          this.currentProjectKey,
                }).then((result) => {
                    if (result.data && result.data.error) {
                        // eslint-disable-next-line no-console
                        console.error(result.data);
                    }
                });
            },

            deleteDevice(key) {
                const cloudFunctionName = 'deleteDevice';
                const deleteDeviceCloudFunction = getCloudFunctionByName(cloudFunctionName);

                deleteDeviceCloudFunction({
                    deviceToBeDeletedKey: key,
                    currProjectID:        this.currentProjectKey,
                }).then((result) => {
                    if (result.data && result.data.error) {
                        // eslint-disable-next-line no-console
                        console.error(result.data);
                    }
                });
            },
        },
    };
</script>

<style scoped>
    .shortcutButton {
        margin-top: 3%;
        margin-right: 3%;
    }
</style>
