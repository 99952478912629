const _ = require('underscore');

const dbFunctions = {
    getBeforeSnapshotJSON(snapshot) {
        return dbFunctions.getSnapshotJSON(snapshot, true);
    },

    getAfterSnapshotJSON(snapshot) {
        return dbFunctions.getSnapshotJSON(snapshot, false);
    },

    getSnapshotJSON(snapshot, getBefore) {
        if (getBefore && snapshot.before.exists()) {
            return snapshot.before.toJSON();
        } if (!getBefore && snapshot.after.exists()) {
            return snapshot.after.toJSON();
        }
        return {};
    },

    // This could be cleaner and moar better. -JS
    isValidIdealCMData(cmKey, stageKey, stageNumber) {
        if (!_.isString(cmKey) || !_.isString(stageKey) || !_.isString(stageNumber)) {
            return false;
        }
        return true;
    },

    refPathForUpdatedAtWithTypeAndKey(type, key) {
        return `${dbFunctions.refPathForTypeAndKey(type, key)}/updatedAt`;
    },

    refPathForTypeAndKey(type, key) {
        return dbFunctions.refPathByArray([type, key]);
    },

    refPathByArray(arr) {
        let tempPath = '/';

        if (!_.isArray(arr)) {
            return tempPath;
        }

        arr.forEach((val) => {
            tempPath = `${tempPath}/${val}`;
        });

        // Get rid of a double starting /
        const startingDoubleSlashesPattern = /^\/\//;
        return tempPath.replace(startingDoubleSlashesPattern, '/');
    },

    getProjectsCanUseStringByTypeAndKey(type, key, projectKey) {
        if (type === 'connection') {
            return dbFunctions.refPathByArray(['projects_can_use_connection', projectKey, 'connectionKeys', key]);
        } if (type === 'receiverStage') {
            return dbFunctions.refPathByArray(['projects_can_use_sequence_item', projectKey, 'sequenceItemKeys', key]);
        } if (type === 'sequence') {
            return dbFunctions.refPathByArray(['projects_can_use_sequence', projectKey, 'sequenceKeys', key]);
        } if (type === 'connectionMonitor') {
            return dbFunctions.refPathByArray(['projects_can_use_connection_monitors', projectKey, 'connectionMonitorKeys', key]);
        } if (type === 'device') {
            return dbFunctions.refPathByArray(['projects_can_use_device', projectKey, 'deviceKeys', key]);
        }
        return null;
    },
};

module.exports = dbFunctions;
