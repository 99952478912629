const moment = require('moment');

// 12:30:20 AM (12/25/2020)
const formatTimestamp = (
    timestamp,
    formatStyle = 'HH:mm:ss A (MM/DD/YYYY)',
) => moment(timestamp).format(formatStyle);

const leftTruncate = (fullString, truncationLength = 5, truncateString = '...') => {
    if (fullString.length < truncationLength) {
        return `${truncateString}${fullString}`;
    }
    return `${truncateString}${fullString.substr(fullString.length - truncationLength)}`;
};

const leftTruncateForAnyLength = (fullString) => {
    if (fullString.length > 4) {
        return leftTruncate(fullString);
    }
    return fullString;
};

const formattedDeltaMinutesSeconds = (millis) => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const formattedDeltaSeconds = (millis) => {
    const seconds = (millis / 1000).toFixed(2);
    return (seconds < 10 ? '0' : '') + seconds;
};
const copyString = async (stringToCopy) => navigator.clipboard.writeText(stringToCopy);

module.exports = {
    formattedDeltaMinutesSeconds,
    formattedDeltaSeconds,
    formatTimestamp,
    leftTruncate,
    leftTruncateForAnyLength,
    copyString,
};
